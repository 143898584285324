define('jason-frontend/components/export-invoice-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/helpers/uses-stable-feature', 'jason-frontend/api'], function (exports, Ember, InboundActions, uses_stable_feature, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectedMode: 'new',
    actions: {
      confirm: function confirm() {
        var selectedInvoice = this.get('selectedInvoice');
        var selectedCustomer = null;
        if (this.get('selectedMode') === 'compositeStable') {
          selectedInvoice = this.get('selectedStableOwnerInvoice');
        } else if (this.get('selectedMode') === 'newStableCustomer') {
          selectedInvoice = this.get('selectedStableCustomerInvoice');
          selectedCustomer = this.get('selectedStableCustomer');
        }
        this.sendAction('confirm', this.get('tpId'), this.get('selectedMode'), selectedInvoice, selectedCustomer);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      selectMode: function selectMode(mode) {
        this.set('selectedMode', mode);
      },
      load: function load(tpId, data, mode) {
        var self = this;
        this.set('tpId', tpId);
        this.set('selectedMode', mode);

        if (mode === 'composite' && uses_stable_feature.usesStableFeature() && data.invoices.length === 0) {
          this.set('selectedMode', 'new');
        }

        this.set('invoices', data);
        if (data && data.invoices && data.invoices.length > 0) {
          self.set('selectedInvoice', data.invoices[0].id);
        }
      }
    },
    watchCustomers: (function () {
      var customerId = this.get('selectedStableCustomer');
      var self = this;
      this.set('stableCustomerInvoices', null);
      this.set('selectedStableCustomerInvoice', null);

      $.ajax({
        url: "/api/treatmentPatients/0/checkInvoicesForCustomer/" + customerId,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('stableCustomerInvoices', data.treatmentPatientInvoiceEntry);
      });
    }).observes('selectedStableCustomer')
  });

});