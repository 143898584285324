define('jason-frontend/templates/practicemgmt/admission', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 26,
                      "column": 12
                    },
                    "end": {
                      "line": 31,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-label");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element54 = dom.childAt(fragment, [1]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createAttrMorph(element54, 'title');
                  morphs[1] = dom.createAttrMorph(element54, 'class');
                  morphs[2] = dom.createAttrMorph(element54, 'style');
                  morphs[3] = dom.createElementMorph(element54);
                  morphs[4] = dom.createMorphAt(dom.childAt(element54, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[27,28],[27,42]]]]]]],
                  ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[27,121],[27,134]]]],"selected",""],[],["loc",[null,[27,100],[27,150]]]]," symbol symbol-circle symbol-40px"]]],
                  ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[27,212],[27,222]]]]," !important;"]]],
                  ["element","action",["toggleResource",["get","user",["loc",[null,[27,72],[27,76]]]]],[],["loc",[null,[27,46],[27,78]]]],
                  ["content","user.capitals",["loc",[null,[28,42],[28,59]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 33,
                        "column": 16
                      },
                      "end": {
                        "line": 37,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","symbol-label");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"src","assets/images/icons/notassigned.svg");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 37,
                        "column": 16
                      },
                      "end": {
                        "line": 40,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","symbol-label");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","user.capitals",["loc",[null,[38,44],[38,61]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 31,
                      "column": 12
                    },
                    "end": {
                      "line": 42,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element53 = dom.childAt(fragment, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createAttrMorph(element53, 'title');
                  morphs[1] = dom.createAttrMorph(element53, 'class');
                  morphs[2] = dom.createElementMorph(element53);
                  morphs[3] = dom.createMorphAt(element53,1,1);
                  return morphs;
                },
                statements: [
                  ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[32,28],[32,42]]]]]]],
                  ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[32,121],[32,134]]]],"selected",""],[],["loc",[null,[32,100],[32,150]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[32,186],[32,196]]]]]]],
                  ["element","action",["toggleResource",["get","user",["loc",[null,[32,72],[32,76]]]]],[],["loc",[null,[32,46],[32,78]]]],
                  ["block","if",[["subexpr","eq",[["get","user.id",["loc",[null,[33,26],[33,33]]]],"9998"],[],["loc",[null,[33,22],[33,41]]]]],[],0,1,["loc",[null,[33,16],[40,23]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 12
                  },
                  "end": {
                    "line": 43,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","user.colorHex",["loc",[null,[26,18],[26,31]]]]],[],0,1,["loc",[null,[26,12],[42,19]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 10
                },
                "end": {
                  "line": 44,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","user.active",["loc",[null,[25,18],[25,29]]]]],[],0,null,["loc",[null,[25,12],[43,19]]]]
            ],
            locals: ["user"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 4
              },
              "end": {
                "line": 47,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","quick-filter mb10 mtm10 col l-24/24");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","title");
            var el3 = dom.createTextNode("Mitarbeiter:innen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-group symbol-hover flex-shrink-0 me-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","users",["loc",[null,[24,18],[24,23]]]]],[],0,null,["loc",[null,[24,10],[44,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 69,
                    "column": 24
                  },
                  "end": {
                    "line": 70,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[70,64],[70,91]]]],
                ["content","item.customer.lastname",["loc",[null,[70,92],[70,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 73,
                      "column": 24
                    },
                    "end": {
                      "line": 73,
                      "column": 256
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1,"class","animal-icon");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[73,115],[73,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[73,155],[73,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[73,200],[73,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[73,83],[73,252]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 74,
                      "column": 22
                    },
                    "end": {
                      "line": 74,
                      "column": 149
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[74,92],[74,116]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[74,125],[74,147]]]]],[],[]]],["loc",[null,[74,73],[74,149]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 75,
                      "column": 22
                    },
                    "end": {
                      "line": 75,
                      "column": 190
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[75,61],[75,190]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 81,
                          "column": 28
                        },
                        "end": {
                          "line": 86,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element41 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element41, 'title');
                      morphs[1] = dom.createAttrMorph(element41, 'style');
                      morphs[2] = dom.createMorphAt(dom.childAt(element41, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[82,44],[82,72]]]]]]],
                      ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[82,162],[82,186]]]]," !important;"]]],
                      ["content","patientInfo.doctor.capitals",["loc",[null,[83,60],[83,91]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 86,
                          "column": 28
                        },
                        "end": {
                          "line": 91,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element40 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element40, 'title');
                      morphs[1] = dom.createAttrMorph(element40, 'class');
                      morphs[2] = dom.createMorphAt(dom.childAt(element40, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[87,44],[87,72]]]]]]],
                      ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[87,122],[87,149]]]],"selected",""],[],["loc",[null,[87,101],[87,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[87,201],[87,225]]]]]]],
                      ["content","patientInfo.doctor.capitals",["loc",[null,[88,60],[88,91]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 80,
                        "column": 26
                      },
                      "end": {
                        "line": 92,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[81,34],[81,61]]]]],[],0,1,["loc",[null,[81,28],[91,35]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 94,
                          "column": 28
                        },
                        "end": {
                          "line": 99,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                      var el2 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element39 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element39, 'title');
                      morphs[1] = dom.createAttrMorph(element39, 'style');
                      morphs[2] = dom.createMorphAt(dom.childAt(element39, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[95,44],[95,75]]]]]]],
                      ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[95,165],[95,192]]]]," !important;"]]],
                      ["content","patientInfo.assistant.capitals",["loc",[null,[96,62],[96,96]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 99,
                          "column": 28
                        },
                        "end": {
                          "line": 104,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      var el2 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element38 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element38, 'title');
                      morphs[1] = dom.createAttrMorph(element38, 'class');
                      morphs[2] = dom.createMorphAt(dom.childAt(element38, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[100,44],[100,75]]]]]]],
                      ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[100,125],[100,155]]]],"selected",""],[],["loc",[null,[100,104],[100,171]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[100,207],[100,234]]]]]]],
                      ["content","patientInfo.assistant.capitals",["loc",[null,[101,62],[101,96]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 93,
                        "column": 26
                      },
                      "end": {
                        "line": 105,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[94,34],[94,64]]]]],[],0,1,["loc",[null,[94,28],[104,35]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 78,
                      "column": 24
                    },
                    "end": {
                      "line": 106,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","patientInfo.doctor",["loc",[null,[80,32],[80,50]]]]],[],0,null,["loc",[null,[80,26],[92,33]]]],
                  ["block","if",[["get","patientInfo.assistant",["loc",[null,[93,32],[93,53]]]]],[],1,null,["loc",[null,[93,26],[105,33]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 18
                  },
                  "end": {
                    "line": 113,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","flex-center mb5 mt5");
                dom.setAttribute(el1,"style","flex-direction: row");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","symbol-group");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element42 = dom.childAt(fragment, [1]);
                var element43 = dom.childAt(element42, [9]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(element42,1,1);
                morphs[1] = dom.createMorphAt(element42,3,3);
                morphs[2] = dom.createMorphAt(element42,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element42, [7]),1,1);
                morphs[4] = dom.createElementMorph(element43);
                morphs[5] = dom.createMorphAt(element43,1,1);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patientInfo.patient.category",["loc",[null,[73,30],[73,58]]]]],[],0,null,["loc",[null,[73,24],[73,263]]]],
                ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[74,49],[74,71]]]]],[],1,null,["loc",[null,[74,22],[74,161]]]],
                ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[75,28],[75,59]]]]],[],2,null,["loc",[null,[75,22],[75,197]]]],
                ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[78,34],[78,55]]]],["get","patientInfo.doctor",["loc",[null,[78,56],[78,74]]]]],[],["loc",[null,[78,30],[78,75]]]]],[],3,null,["loc",[null,[78,24],[106,31]]]],
                ["element","action",["admissionPatient",["get","item.customer.id",["loc",[null,[109,133],[109,149]]]],["get","patientInfo.patient.id",["loc",[null,[109,150],[109,172]]]],["get","item.appointmentId",["loc",[null,[109,173],[109,191]]]]],[],["loc",[null,[109,105],[109,193]]]],
                ["inline","button-admission",[],["size","32","color","#fff","showStroke",false,"content","Check-In"],["loc",[null,[110,24],[110,103]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0, child1, child2, child3]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 12
                },
                "end": {
                  "line": 116,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
              dom.setAttribute(el2,"data-label","Termin");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element44 = dom.childAt(fragment, [1]);
              var element45 = dom.childAt(element44, [1]);
              var element46 = dom.childAt(element44, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element45, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element45, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element46, [1]),0,0);
              morphs[3] = dom.createMorphAt(element46,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[65,97],[65,109]]]],"DD. MM. YYYY"],[],["loc",[null,[65,83],[65,126]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[66,50],[66,62]]]],"HH:mm"],[],["loc",[null,[66,36],[66,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[69,52],[69,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[69,24],[70,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[71,26],[71,48]]]]],[],1,null,["loc",[null,[71,18],[113,27]]]]
            ],
            locals: ["item"],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 139,
                      "column": 22
                    },
                    "end": {
                      "line": 146,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","sub block mt5");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","sub");
                  dom.setAttribute(el2,"style","display: inline");
                  var el3 = dom.createTextNode("Termin: ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("label");
                  dom.setAttribute(el2,"for","time");
                  dom.setAttribute(el2,"class","input-element");
                  var el3 = dom.createTextNode("\n                             ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("input");
                  dom.setAttribute(el3,"style","font-size: 14px");
                  dom.setAttribute(el3,"autocomplete","false");
                  dom.setAttribute(el3,"type","text");
                  dom.setAttribute(el3,"placeholder","");
                  dom.setAttribute(el3,"class","input date-picker__input time time-small");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element34 = dom.childAt(fragment, [1, 3, 1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element34, 'id');
                  morphs[1] = dom.createAttrMorph(element34, 'value');
                  morphs[2] = dom.createAttrMorph(element34, 'onclick');
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[143,108],[143,115]]]]]]],
                  ["attribute","value",["concat",[["get","item.time",["loc",[null,[143,143],[143,152]]]]]]],
                  ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[143,233],[143,237]]]]],[],["loc",[null,[143,213],[143,239]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 149,
                      "column": 28
                    },
                    "end": {
                      "line": 150,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[150,64],[150,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[150,92],[150,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 155,
                            "column": 24
                          },
                          "end": {
                            "line": 159,
                            "column": 85
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("i");
                        dom.setAttribute(el1,"class","animal-icon");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                        return morphs;
                      },
                      statements: [
                        ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[156,81],[156,112]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[157,71],[157,107]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[158,71],[158,104]]]]],[],[]],"classNames","mr5"],["loc",[null,[156,49],[159,81]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 160,
                            "column": 28
                          },
                          "end": {
                            "line": 161,
                            "column": 92
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[161,35],[161,59]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[161,68],[161,90]]]]],[],[]]],["loc",[null,[160,79],[161,92]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child2 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 162,
                            "column": 28
                          },
                          "end": {
                            "line": 164,
                            "column": 63
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[162,67],[164,63]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child3 = (function() {
                    var child0 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 169,
                                "column": 28
                              },
                              "end": {
                                "line": 174,
                                "column": 28
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                              ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element31 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element31, 'title');
                            morphs[1] = dom.createAttrMorph(element31, 'style');
                            morphs[2] = dom.createMorphAt(dom.childAt(element31, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[170,44],[170,72]]]]]]],
                            ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[170,162],[170,186]]]]," !important;"]]],
                            ["content","patientInfo.doctor.capitals",["loc",[null,[171,60],[171,91]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 174,
                                "column": 28
                              },
                              "end": {
                                "line": 179,
                                "column": 28
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                              ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element30 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element30, 'title');
                            morphs[1] = dom.createAttrMorph(element30, 'class');
                            morphs[2] = dom.createMorphAt(dom.childAt(element30, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[175,44],[175,72]]]]]]],
                            ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[175,122],[175,149]]]],"selected",""],[],["loc",[null,[175,101],[175,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[175,201],[175,225]]]]]]],
                            ["content","patientInfo.doctor.capitals",["loc",[null,[176,60],[176,91]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 168,
                              "column": 26
                            },
                            "end": {
                              "line": 180,
                              "column": 26
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[169,34],[169,61]]]]],[],0,1,["loc",[null,[169,28],[179,35]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 182,
                                "column": 34
                              },
                              "end": {
                                "line": 187,
                                "column": 34
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                    ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                            var el2 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element29 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element29, 'title');
                            morphs[1] = dom.createAttrMorph(element29, 'style');
                            morphs[2] = dom.createMorphAt(dom.childAt(element29, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[183,50],[183,81]]]]]]],
                            ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[183,171],[183,198]]]]," !important;"]]],
                            ["content","patientInfo.assistant.capitals",["loc",[null,[184,62],[184,96]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 187,
                                "column": 34
                              },
                              "end": {
                                "line": 192,
                                "column": 34
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                    ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            var el2 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element28 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element28, 'title');
                            morphs[1] = dom.createAttrMorph(element28, 'class');
                            morphs[2] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[188,50],[188,81]]]]]]],
                            ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[188,131],[188,161]]]],"selected",""],[],["loc",[null,[188,110],[188,177]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[188,213],[188,240]]]]]]],
                            ["content","patientInfo.assistant.capitals",["loc",[null,[189,62],[189,96]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 181,
                              "column": 32
                            },
                            "end": {
                              "line": 193,
                              "column": 32
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[182,40],[182,70]]]]],[],0,1,["loc",[null,[182,34],[192,41]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 166,
                            "column": 28
                          },
                          "end": {
                            "line": 194,
                            "column": 28
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor",["loc",[null,[168,32],[168,50]]]]],[],0,null,["loc",[null,[168,26],[180,33]]]],
                        ["block","if",[["get","patientInfo.assistant",["loc",[null,[181,38],[181,59]]]]],[],1,null,["loc",[null,[181,32],[193,39]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 153,
                          "column": 24
                        },
                        "end": {
                          "line": 201,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","flex-center mb5 mt5");
                      dom.setAttribute(el1,"style","flex-direction: row");
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                            ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                            ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-group");
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("                          ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n\n                            ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
                      var el3 = dom.createTextNode("\n                              ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                            ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element32 = dom.childAt(fragment, [1]);
                      var element33 = dom.childAt(element32, [9]);
                      var morphs = new Array(6);
                      morphs[0] = dom.createMorphAt(element32,1,1);
                      morphs[1] = dom.createMorphAt(element32,3,3);
                      morphs[2] = dom.createMorphAt(element32,5,5);
                      morphs[3] = dom.createMorphAt(dom.childAt(element32, [7]),1,1);
                      morphs[4] = dom.createElementMorph(element33);
                      morphs[5] = dom.createMorphAt(element33,1,1);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.patient.category",["loc",[null,[155,30],[155,58]]]]],[],0,null,["loc",[null,[155,24],[159,92]]]],
                      ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[160,55],[160,77]]]]],[],1,null,["loc",[null,[160,28],[161,104]]]],
                      ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[162,34],[162,65]]]]],[],2,null,["loc",[null,[162,28],[164,70]]]],
                      ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[166,38],[166,59]]]],["get","patientInfo.doctor",["loc",[null,[166,60],[166,78]]]]],[],["loc",[null,[166,34],[166,79]]]]],[],3,null,["loc",[null,[166,28],[194,35]]]],
                      ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[197,149],[197,163]]]]],[],["loc",[null,[197,111],[197,165]]]],
                      ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandeln"],["loc",[null,[198,30],[198,113]]]]
                    ],
                    locals: [],
                    templates: [child0, child1, child2, child3]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 152,
                        "column": 24
                      },
                      "end": {
                        "line": 202,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[153,59],[153,70]]]],["get","selectedUsers",["loc",[null,[153,71],[153,84]]]],["get","changeTrigger",["loc",[null,[153,85],[153,98]]]]],[],["loc",[null,[153,30],[153,99]]]]],[],0,null,["loc",[null,[153,24],[201,31]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 151,
                      "column": 22
                    },
                    "end": {
                      "line": 203,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[152,34],[152,54]]]],"in_waitingroom"],[],["loc",[null,[152,30],[152,72]]]]],[],0,null,["loc",[null,[152,24],[202,31]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 134,
                    "column": 14
                  },
                  "end": {
                    "line": 207,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell  l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element35 = dom.childAt(fragment, [1]);
                var element36 = dom.childAt(element35, [1]);
                var element37 = dom.childAt(element35, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element36, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element36, [3]),0,0);
                morphs[2] = dom.createMorphAt(element36,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element37, [1]),0,0);
                morphs[4] = dom.createMorphAt(element37,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[137,101],[137,113]]]],"DD. MM. YYYY"],[],["loc",[null,[137,87],[137,130]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[138,54],[138,66]]]],"HH:mm"],[],["loc",[null,[138,40],[138,76]]]],
                ["block","if",[["get","item.time",["loc",[null,[139,28],[139,37]]]]],[],0,null,["loc",[null,[139,22],[146,29]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[149,56],[149,72]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[149,28],[150,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[151,30],[151,52]]]]],[],2,null,["loc",[null,[151,22],[203,31]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 133,
                  "column": 12
                },
                "end": {
                  "line": 208,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[134,41],[134,45]]]],["get","selectedUsers",["loc",[null,[134,46],[134,59]]]],["get","changeTrigger",["loc",[null,[134,60],[134,73]]]]],[],["loc",[null,[134,20],[134,74]]]]],[],0,null,["loc",[null,[134,14],[207,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 231,
                      "column": 18
                    },
                    "end": {
                      "line": 238,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","sub block mt5");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","sub");
                  dom.setAttribute(el2,"style","display: inline");
                  var el3 = dom.createTextNode("Termin: ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("label");
                  dom.setAttribute(el2,"for","time");
                  dom.setAttribute(el2,"class","input-element");
                  var el3 = dom.createTextNode("\n                             ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("input");
                  dom.setAttribute(el3,"style","font-size: 14px");
                  dom.setAttribute(el3,"autocomplete","false");
                  dom.setAttribute(el3,"type","text");
                  dom.setAttribute(el3,"placeholder","");
                  dom.setAttribute(el3,"class","input date-picker__input time time-small");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element24 = dom.childAt(fragment, [1, 3, 1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element24, 'id');
                  morphs[1] = dom.createAttrMorph(element24, 'value');
                  morphs[2] = dom.createAttrMorph(element24, 'onclick');
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[235,108],[235,115]]]]]]],
                  ["attribute","value",["concat",[["get","item.time",["loc",[null,[235,143],[235,152]]]]]]],
                  ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[235,233],[235,237]]]]],[],["loc",[null,[235,213],[235,239]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 241,
                      "column": 24
                    },
                    "end": {
                      "line": 242,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[242,64],[242,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[242,92],[242,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 247,
                            "column": 24
                          },
                          "end": {
                            "line": 247,
                            "column": 256
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("i");
                        dom.setAttribute(el1,"class","animal-icon");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                        return morphs;
                      },
                      statements: [
                        ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[247,115],[247,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[247,155],[247,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[247,200],[247,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[247,83],[247,252]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 248,
                            "column": 24
                          },
                          "end": {
                            "line": 248,
                            "column": 151
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[248,94],[248,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[248,127],[248,149]]]]],[],[]]],["loc",[null,[248,75],[248,151]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child2 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 249,
                            "column": 24
                          },
                          "end": {
                            "line": 249,
                            "column": 192
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[249,63],[249,192]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child3 = (function() {
                    var child0 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 254,
                                "column": 30
                              },
                              "end": {
                                "line": 259,
                                "column": 44
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element22 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element22, 'title');
                            morphs[1] = dom.createAttrMorph(element22, 'style');
                            morphs[2] = dom.createMorphAt(dom.childAt(element22, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[255,46],[255,74]]]]]]],
                            ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[255,164],[255,188]]]]," !important;"]]],
                            ["content","patientInfo.doctor.capitals",["loc",[null,[256,60],[256,91]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 259,
                                "column": 44
                              },
                              "end": {
                                "line": 264,
                                "column": 30
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                              ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element21 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element21, 'title');
                            morphs[1] = dom.createAttrMorph(element21, 'class');
                            morphs[2] = dom.createMorphAt(dom.childAt(element21, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[260,60],[260,88]]]]]]],
                            ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[260,138],[260,165]]]],"selected",""],[],["loc",[null,[260,117],[260,181]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[260,217],[260,241]]]]]]],
                            ["content","patientInfo.doctor.capitals",["loc",[null,[261,60],[261,91]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 253,
                              "column": 26
                            },
                            "end": {
                              "line": 265,
                              "column": 26
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[254,36],[254,63]]]]],[],0,1,["loc",[null,[254,30],[264,37]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 267,
                                "column": 30
                              },
                              "end": {
                                "line": 272,
                                "column": 30
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                            var el2 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element20 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element20, 'title');
                            morphs[1] = dom.createAttrMorph(element20, 'style');
                            morphs[2] = dom.createMorphAt(dom.childAt(element20, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[268,46],[268,77]]]]]]],
                            ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[268,167],[268,194]]]]," !important;"]]],
                            ["content","patientInfo.assistant.capitals",["loc",[null,[269,62],[269,96]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 272,
                                "column": 30
                              },
                              "end": {
                                "line": 277,
                                "column": 30
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            var el2 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element19 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element19, 'title');
                            morphs[1] = dom.createAttrMorph(element19, 'class');
                            morphs[2] = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[273,46],[273,77]]]]]]],
                            ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[273,127],[273,157]]]],"selected",""],[],["loc",[null,[273,106],[273,173]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[273,209],[273,236]]]]]]],
                            ["content","patientInfo.assistant.capitals",["loc",[null,[274,62],[274,96]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 266,
                              "column": 28
                            },
                            "end": {
                              "line": 278,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[267,36],[267,66]]]]],[],0,1,["loc",[null,[267,30],[277,37]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 252,
                            "column": 24
                          },
                          "end": {
                            "line": 279,
                            "column": 24
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor",["loc",[null,[253,32],[253,50]]]]],[],0,null,["loc",[null,[253,26],[265,33]]]],
                        ["block","if",[["get","patientInfo.assistant",["loc",[null,[266,34],[266,55]]]]],[],1,null,["loc",[null,[266,28],[278,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child4 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 282,
                            "column": 24
                          },
                          "end": {
                            "line": 284,
                            "column": 24
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandlung öffnen"],["loc",[null,[283,26],[283,117]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 245,
                          "column": 22
                        },
                        "end": {
                          "line": 286,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","flex-center mb5 mt5");
                      dom.setAttribute(el1,"style","flex-direction: row");
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-group");
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("                          ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("                      ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element23 = dom.childAt(fragment, [1]);
                      var morphs = new Array(5);
                      morphs[0] = dom.createMorphAt(element23,1,1);
                      morphs[1] = dom.createMorphAt(element23,3,3);
                      morphs[2] = dom.createMorphAt(element23,5,5);
                      morphs[3] = dom.createMorphAt(dom.childAt(element23, [7]),1,1);
                      morphs[4] = dom.createMorphAt(element23,9,9);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.patient.category",["loc",[null,[247,30],[247,58]]]]],[],0,null,["loc",[null,[247,24],[247,263]]]],
                      ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[248,51],[248,73]]]]],[],1,null,["loc",[null,[248,24],[248,163]]]],
                      ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[249,30],[249,61]]]]],[],2,null,["loc",[null,[249,24],[249,199]]]],
                      ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[252,34],[252,55]]]],["get","patientInfo.doctor",["loc",[null,[252,56],[252,74]]]]],[],["loc",[null,[252,30],[252,75]]]]],[],3,null,["loc",[null,[252,24],[279,31]]]],
                      ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[282,68],[282,82]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[282,83],[282,110]]]]],["class","mr0 ml10 icon-button icon-button--default list-action-square"],4,null,["loc",[null,[282,24],[284,36]]]]
                    ],
                    locals: [],
                    templates: [child0, child1, child2, child3, child4]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 244,
                        "column": 22
                      },
                      "end": {
                        "line": 287,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[245,57],[245,68]]]],["get","selectedUsers",["loc",[null,[245,69],[245,82]]]],["get","changeTrigger",["loc",[null,[245,83],[245,96]]]]],[],["loc",[null,[245,28],[245,97]]]]],[],0,null,["loc",[null,[245,22],[286,29]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 243,
                      "column": 20
                    },
                    "end": {
                      "line": 288,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[244,32],[244,52]]]],"in_treatment"],[],["loc",[null,[244,28],[244,68]]]]],[],0,null,["loc",[null,[244,22],[287,29]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 226,
                    "column": 14
                  },
                  "end": {
                    "line": 291,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element25 = dom.childAt(fragment, [1]);
                var element26 = dom.childAt(element25, [1]);
                var element27 = dom.childAt(element25, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element26, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element26, [3]),0,0);
                morphs[2] = dom.createMorphAt(element26,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element27, [1]),0,0);
                morphs[4] = dom.createMorphAt(element27,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[229,97],[229,109]]]],"DD. MM. YYYY"],[],["loc",[null,[229,83],[229,126]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[230,50],[230,62]]]],"HH:mm"],[],["loc",[null,[230,36],[230,72]]]],
                ["block","if",[["get","item.time",["loc",[null,[231,24],[231,33]]]]],[],0,null,["loc",[null,[231,18],[238,25]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[241,52],[241,68]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[241,24],[242,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[243,28],[243,50]]]]],[],2,null,["loc",[null,[243,20],[288,29]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 225,
                  "column": 12
                },
                "end": {
                  "line": 292,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[226,41],[226,45]]]],["get","selectedUsers",["loc",[null,[226,46],[226,59]]]],["get","changeTrigger",["loc",[null,[226,60],[226,73]]]]],[],["loc",[null,[226,20],[226,74]]]]],[],0,null,["loc",[null,[226,14],[291,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 315,
                      "column": 24
                    },
                    "end": {
                      "line": 316,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[316,64],[316,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[316,92],[316,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 321,
                            "column": 24
                          },
                          "end": {
                            "line": 321,
                            "column": 256
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("i");
                        dom.setAttribute(el1,"class","animal-icon");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                        return morphs;
                      },
                      statements: [
                        ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[321,115],[321,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[321,155],[321,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[321,200],[321,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[321,83],[321,252]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 323,
                            "column": 0
                          },
                          "end": {
                            "line": 323,
                            "column": 127
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[323,70],[323,94]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[323,103],[323,125]]]]],[],[]]],["loc",[null,[323,51],[323,127]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child2 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 324,
                            "column": 24
                          },
                          "end": {
                            "line": 326,
                            "column": 24
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","block small");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                        return morphs;
                      },
                      statements: [
                        ["content","patientInfo.room.name",["loc",[null,[325,52],[325,77]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child3 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 328,
                            "column": 22
                          },
                          "end": {
                            "line": 328,
                            "column": 190
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[328,61],[328,190]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child4 = (function() {
                    var child0 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 333,
                                "column": 32
                              },
                              "end": {
                                "line": 338,
                                "column": 32
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                  ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                            var el2 = dom.createTextNode("\n                                      ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                      ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element12 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element12, 'title');
                            morphs[1] = dom.createAttrMorph(element12, 'style');
                            morphs[2] = dom.createMorphAt(dom.childAt(element12, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[334,48],[334,76]]]]]]],
                            ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[334,166],[334,190]]]]," !important;"]]],
                            ["content","patientInfo.doctor.capitals",["loc",[null,[335,64],[335,95]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 338,
                                "column": 32
                              },
                              "end": {
                                "line": 343,
                                "column": 32
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                  ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            var el2 = dom.createTextNode("\n                                      ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                      ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element11 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element11, 'title');
                            morphs[1] = dom.createAttrMorph(element11, 'class');
                            morphs[2] = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[339,48],[339,76]]]]]]],
                            ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[339,126],[339,153]]]],"selected",""],[],["loc",[null,[339,105],[339,169]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[339,205],[339,229]]]]]]],
                            ["content","patientInfo.doctor.capitals",["loc",[null,[340,64],[340,95]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 332,
                              "column": 30
                            },
                            "end": {
                              "line": 344,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[333,38],[333,65]]]]],[],0,1,["loc",[null,[333,32],[343,39]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 346,
                                "column": 32
                              },
                              "end": {
                                "line": 351,
                                "column": 32
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                  ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                            var el2 = dom.createTextNode("\n                                        ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                        ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                      ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element10 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element10, 'title');
                            morphs[1] = dom.createAttrMorph(element10, 'style');
                            morphs[2] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[347,48],[347,79]]]]]]],
                            ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[347,169],[347,196]]]]," !important;"]]],
                            ["content","patientInfo.assistant.capitals",["loc",[null,[348,66],[348,100]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 351,
                                "column": 32
                              },
                              "end": {
                                "line": 356,
                                "column": 32
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                  ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            var el2 = dom.createTextNode("\n                                        ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                        ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                      ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element9 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element9, 'title');
                            morphs[1] = dom.createAttrMorph(element9, 'class');
                            morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[352,48],[352,79]]]]]]],
                            ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[352,129],[352,159]]]],"selected",""],[],["loc",[null,[352,108],[352,175]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[352,211],[352,238]]]]]]],
                            ["content","patientInfo.assistant.capitals",["loc",[null,[353,66],[353,100]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 345,
                              "column": 30
                            },
                            "end": {
                              "line": 357,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[346,38],[346,68]]]]],[],0,1,["loc",[null,[346,32],[356,39]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 330,
                            "column": 26
                          },
                          "end": {
                            "line": 358,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor",["loc",[null,[332,36],[332,54]]]]],[],0,null,["loc",[null,[332,30],[344,37]]]],
                        ["block","if",[["get","patientInfo.assistant",["loc",[null,[345,36],[345,57]]]]],[],1,null,["loc",[null,[345,30],[357,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 319,
                          "column": 20
                        },
                        "end": {
                          "line": 364,
                          "column": 20
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","flex-center mb5 mt5");
                      dom.setAttribute(el1,"style","flex-direction: row");
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("                      ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-group");
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("                        ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2,"class","ml5 exp icon-button icon-button--default list-action-square");
                      var el3 = dom.createTextNode("\n                          ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("span");
                      var el4 = dom.createTextNode("Behandeln");
                      dom.appendChild(el3, el4);
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                        ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element13 = dom.childAt(fragment, [1]);
                      var element14 = dom.childAt(element13, [3]);
                      var element15 = dom.childAt(element13, [9]);
                      var morphs = new Array(7);
                      morphs[0] = dom.createMorphAt(element13,1,1);
                      morphs[1] = dom.createMorphAt(element14,1,1);
                      morphs[2] = dom.createMorphAt(element14,3,3);
                      morphs[3] = dom.createMorphAt(element13,5,5);
                      morphs[4] = dom.createMorphAt(dom.childAt(element13, [7]),1,1);
                      morphs[5] = dom.createElementMorph(element15);
                      morphs[6] = dom.createMorphAt(element15,1,1);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.patient.category",["loc",[null,[321,30],[321,58]]]]],[],0,null,["loc",[null,[321,24],[321,263]]]],
                      ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[323,27],[323,49]]]]],[],1,null,["loc",[null,[323,0],[323,139]]]],
                      ["block","if",[["get","patientInfo.room",["loc",[null,[324,30],[324,46]]]]],[],2,null,["loc",[null,[324,24],[326,31]]]],
                      ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[328,28],[328,59]]]]],[],3,null,["loc",[null,[328,22],[328,197]]]],
                      ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[330,36],[330,57]]]],["get","patientInfo.doctor",["loc",[null,[330,58],[330,76]]]]],[],["loc",[null,[330,32],[330,77]]]]],[],4,null,["loc",[null,[330,26],[358,33]]]],
                      ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[360,131],[360,145]]]]],[],["loc",[null,[360,93],[360,147]]]],
                      ["inline","button-in-treatment",[],["size","40","color","#fff","showStroke",false,"content","In eine Behandlungs-Lokation setzen"],["loc",[null,[361,26],[361,135]]]]
                    ],
                    locals: [],
                    templates: [child0, child1, child2, child3, child4]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 318,
                        "column": 20
                      },
                      "end": {
                        "line": 365,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[319,55],[319,66]]]],["get","selectedUsers",["loc",[null,[319,67],[319,80]]]],["get","changeTrigger",["loc",[null,[319,81],[319,94]]]]],[],["loc",[null,[319,26],[319,95]]]]],[],0,null,["loc",[null,[319,20],[364,27]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 317,
                      "column": 18
                    },
                    "end": {
                      "line": 366,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[318,30],[318,50]]]],"in_station"],[],["loc",[null,[318,26],[318,64]]]]],[],0,null,["loc",[null,[318,20],[365,27]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 308,
                    "column": 14
                  },
                  "end": {
                    "line": 369,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element16 = dom.childAt(fragment, [1]);
                var element17 = dom.childAt(element16, [1]);
                var element18 = dom.childAt(element16, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element17, [3]),0,0);
                morphs[2] = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
                morphs[3] = dom.createMorphAt(element18,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[311,97],[311,109]]]],"DD. MM. YYYY"],[],["loc",[null,[311,83],[311,126]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[312,50],[312,62]]]],"HH:mm"],[],["loc",[null,[312,36],[312,72]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[315,52],[315,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[315,24],[316,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[317,26],[317,48]]]]],[],1,null,["loc",[null,[317,18],[366,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 307,
                  "column": 12
                },
                "end": {
                  "line": 370,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[308,41],[308,45]]]],["get","selectedUsers",["loc",[null,[308,46],[308,59]]]],["get","changeTrigger",["loc",[null,[308,60],[308,73]]]]],[],["loc",[null,[308,20],[308,74]]]]],[],0,null,["loc",[null,[308,14],[369,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 395,
                    "column": 24
                  },
                  "end": {
                    "line": 396,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[396,64],[396,91]]]],
                ["content","item.customer.lastname",["loc",[null,[396,92],[396,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 401,
                        "column": 26
                      },
                      "end": {
                        "line": 405,
                        "column": 87
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1,"class","animal-icon");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[402,83],[402,114]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[403,73],[403,109]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[404,73],[404,106]]]]],[],[]],"classNames","mr5"],["loc",[null,[402,51],[405,83]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 406,
                        "column": 24
                      },
                      "end": {
                        "line": 407,
                        "column": 121
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[406,94],[406,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[407,97],[407,119]]]]],[],[]]],["loc",[null,[406,75],[407,121]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 408,
                        "column": 24
                      },
                      "end": {
                        "line": 410,
                        "column": 109
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[408,63],[410,109]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 412,
                        "column": 22
                      },
                      "end": {
                        "line": 417,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square mr0 ml0 ");
                    dom.setAttribute(el1,"style","margin-left: auto !important;");
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-ready4payment",[],["size","32","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[414,26],[415,89]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child4 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 424,
                          "column": 24
                        },
                        "end": {
                          "line": 426,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandlung öffnen"],["loc",[null,[425,26],[425,117]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 417,
                        "column": 22
                      },
                      "end": {
                        "line": 427,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","ember-view icon-button icon-button--success list-action-square mr0 ml5");
                    dom.setAttribute(el1,"style","margin-left: auto !important;");
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element3);
                    morphs[1] = dom.createMorphAt(element3,1,1);
                    morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openExportInvoicePanel",["get","patientInfo.id",["loc",[null,[419,120],[419,134]]]],["get","patientInfo.info.invoiceId",["loc",[null,[420,120],[420,146]]]]],[],["loc",[null,[418,144],[420,148]]]],
                    ["inline","button-ready4payment",[],["size","32","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[421,26],[422,89]]]],
                    ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[424,68],[424,82]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[424,83],[424,110]]]]],["class","mr0 icon-button icon-button--default list-action-square"],0,null,["loc",[null,[424,24],[426,36]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 398,
                      "column": 20
                    },
                    "end": {
                      "line": 429,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","flex-center mb5 mt5");
                  dom.setAttribute(el1,"style","flex-direction: row");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","flex-center");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1]);
                  var element5 = dom.childAt(element4, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element5,1,1);
                  morphs[1] = dom.createMorphAt(element5,3,3);
                  morphs[2] = dom.createMorphAt(element5,5,5);
                  morphs[3] = dom.createMorphAt(element4,3,3);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","patientInfo.patient.category",["loc",[null,[401,32],[401,60]]]]],[],0,null,["loc",[null,[401,26],[405,94]]]],
                  ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[406,51],[406,73]]]]],[],1,null,["loc",[null,[406,24],[407,133]]]],
                  ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[408,30],[408,61]]]]],[],2,null,["loc",[null,[408,24],[410,116]]]],
                  ["block","if",[["get","inProgress",["loc",[null,[412,28],[412,38]]]]],[],3,4,["loc",[null,[412,22],[427,29]]]]
                ],
                locals: [],
                templates: [child0, child1, child2, child3, child4]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 397,
                    "column": 18
                  },
                  "end": {
                    "line": 430,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[398,30],[398,50]]]],"ready_for_payment"],[],["loc",[null,[398,26],[398,71]]]]],[],0,null,["loc",[null,[398,20],[429,27]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 387,
                  "column": 12
                },
                "end": {
                  "line": 434,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var element7 = dom.childAt(element6, [1]);
              var element8 = dom.childAt(element6, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element7, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
              morphs[3] = dom.createMorphAt(element8,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[390,97],[390,109]]]],"DD. MM. YYYY"],[],["loc",[null,[390,83],[391,113]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[392,50],[392,62]]]],"HH:mm"],[],["loc",[null,[392,36],[392,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[395,52],[395,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[395,24],[396,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[397,26],[397,48]]]]],[],1,null,["loc",[null,[397,18],[430,27]]]]
            ],
            locals: ["item"],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 0
              },
              "end": {
                "line": 439,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Demnächst");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table grid table--small");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Termin");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Wartezimmer");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table grid table--small");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("In Behandlung");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            dom.setAttribute(el2,"id","stationsCard");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Auf Station");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Bezahlung");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element47 = dom.childAt(fragment, [1, 1]);
            var element48 = dom.childAt(fragment, [3, 1]);
            var element49 = dom.childAt(fragment, [5]);
            var element50 = dom.childAt(element49, [1]);
            var element51 = dom.childAt(element49, [3]);
            var element52 = dom.childAt(fragment, [7, 1]);
            var morphs = new Array(10);
            morphs[0] = dom.createMorphAt(dom.childAt(element47, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element47, [3, 3]),1,1);
            morphs[2] = dom.createMorphAt(dom.childAt(element48, [1, 1]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element48, [3, 3]),1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element50, [1, 1]),0,0);
            morphs[5] = dom.createMorphAt(dom.childAt(element50, [3, 3]),1,1);
            morphs[6] = dom.createMorphAt(dom.childAt(element51, [1, 1]),0,0);
            morphs[7] = dom.createMorphAt(dom.childAt(element51, [3, 3]),1,1);
            morphs[8] = dom.createMorphAt(dom.childAt(element52, [1, 1]),0,0);
            morphs[9] = dom.createMorphAt(dom.childAt(element52, [3, 3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","get-size",[["get","upcomingItems",["loc",[null,[53,92],[53,105]]]]],[],["loc",[null,[53,81],[53,107]]]],
            ["block","each",[["get","upcomingItems",["loc",[null,[62,20],[62,33]]]]],[],0,null,["loc",[null,[62,12],[116,21]]]],
            ["inline","get-size",[["get","waitingroomItems",["loc",[null,[124,92],[124,108]]]]],[],["loc",[null,[124,81],[124,110]]]],
            ["block","each",[["get","waitingroomItems",["loc",[null,[133,20],[133,36]]]]],[],1,null,["loc",[null,[133,12],[208,21]]]],
            ["inline","get-size",[["get","treatmentItems",["loc",[null,[216,92],[216,106]]]]],[],["loc",[null,[216,81],[216,108]]]],
            ["block","each",[["get","treatmentItems",["loc",[null,[225,20],[225,34]]]]],[],2,null,["loc",[null,[225,12],[292,21]]]],
            ["inline","get-size",[["get","stationItems",["loc",[null,[298,92],[298,104]]]]],[],["loc",[null,[298,81],[298,106]]]],
            ["block","each",[["get","stationItems",["loc",[null,[307,20],[307,32]]]]],[],3,null,["loc",[null,[307,12],[370,21]]]],
            ["inline","get-size",[["get","paymentItems",["loc",[null,[378,92],[378,104]]]]],[],["loc",[null,[378,81],[378,106]]]],
            ["block","each",[["get","paymentItems",["loc",[null,[387,20],[387,32]]]]],[],4,null,["loc",[null,[387,12],[434,21]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 441,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","with-context-bar grid");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element55 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element55,1,1);
          morphs[1] = dom.createMorphAt(element55,3,3);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","mainView",["loc",[null,[20,14],[20,22]]]],"tiles"],[],["loc",[null,[20,10],[20,31]]]]],[],0,null,["loc",[null,[20,4],[47,11]]]],
          ["block","if",[["get","users",["loc",[null,[49,6],[49,11]]]]],[],1,null,["loc",[null,[49,0],[439,7]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 457,
                "column": 10
              },
              "end": {
                "line": 459,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createElementMorph(element2);
            morphs[2] = dom.createMorphAt(element2,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","station.selected",["loc",[null,[458,65],[458,81]]]],"selected",""],[],["loc",[null,[458,44],[458,97]]]]]]],
            ["element","action",["toggleStation",["get","station",["loc",[null,[458,124],[458,131]]]]],[],["loc",[null,[458,99],[458,133]]]],
            ["content","station.name",["loc",[null,[458,134],[458,150]]]]
          ],
          locals: ["station"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 454,
              "column": 6
            },
            "end": {
              "line": 461,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter mb0 col l-12/24");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Stationen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","stations",["loc",[null,[457,18],[457,26]]]]],[],0,null,["loc",[null,[457,10],[459,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 468,
                  "column": 16
                },
                "end": {
                  "line": 476,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element1, 'title');
              morphs[1] = dom.createAttrMorph(element1, 'class');
              morphs[2] = dom.createAttrMorph(element1, 'style');
              morphs[3] = dom.createElementMorph(element1);
              morphs[4] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[469,32],[469,46]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[470,65],[470,78]]]],"selected",""],[],["loc",[null,[470,44],[471,69]]]]," symbol symbol-circle symbol-40px"]]],
              ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[472,50],[472,60]]]]," !important;"]]],
              ["element","action",["toggleResource",["get","user",["loc",[null,[469,76],[469,80]]]]],[],["loc",[null,[469,50],[469,82]]]],
              ["content","user.capitals",["loc",[null,[473,46],[473,63]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 480,
                    "column": 20
                  },
                  "end": {
                    "line": 484,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-label");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/notassigned.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 484,
                    "column": 20
                  },
                  "end": {
                    "line": 487,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-label");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","user.capitals",["loc",[null,[485,48],[485,65]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 476,
                  "column": 16
                },
                "end": {
                  "line": 489,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element0, 'title');
              morphs[1] = dom.createAttrMorph(element0, 'class');
              morphs[2] = dom.createElementMorph(element0);
              morphs[3] = dom.createMorphAt(element0,1,1);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[477,32],[477,46]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[478,65],[478,78]]]],"selected",""],[],["loc",[null,[478,44],[479,69]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[479,105],[479,115]]]]]]],
              ["element","action",["toggleResource",["get","user",["loc",[null,[477,76],[477,80]]]]],[],["loc",[null,[477,50],[477,82]]]],
              ["block","if",[["subexpr","eq",[["get","user.id",["loc",[null,[480,30],[480,37]]]],"9998"],[],["loc",[null,[480,26],[480,45]]]]],[],0,1,["loc",[null,[480,20],[487,27]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 467,
                "column": 14
              },
              "end": {
                "line": 490,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","user.colorHex",["loc",[null,[468,22],[468,35]]]]],[],0,1,["loc",[null,[468,16],[489,23]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 466,
              "column": 12
            },
            "end": {
              "line": 491,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.active",["loc",[null,[467,20],[467,31]]]]],[],0,null,["loc",[null,[467,14],[490,21]]]]
        ],
        locals: ["user"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 536,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","context-bar grid");
        dom.setAttribute(el1,"id","treatmentOverviewNav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-14/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","btn-group");
        dom.setAttribute(el3,"role","group");
        dom.setAttribute(el3,"aria-label","Basic example");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","button");
        var el5 = dom.createTextNode("Kombinierte Ansicht");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","button");
        var el5 = dom.createTextNode(" Nur Kacheln");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","button");
        var el5 = dom.createTextNode("Nur Liste");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","buttons-box col l-10/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","with-icon button context primary");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/add-entry.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Neue Behandlung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","treatmentOverview");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("section");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table-wrapper mb30 mt20 grid");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","quick-filter mb0 col l-12/24");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Filter");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Wartezimmer");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Behandlung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Bereit zum Bezahlen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Station");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","quick-filter mb0 mt10 col l-24/24");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Mitarbeiter:innen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","symbol-group symbol-hover flex-shrink-0 me-2");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element56 = dom.childAt(fragment, [0]);
        var element57 = dom.childAt(element56, [1, 1]);
        var element58 = dom.childAt(element57, [1]);
        var element59 = dom.childAt(element57, [3]);
        var element60 = dom.childAt(element57, [5]);
        var element61 = dom.childAt(element56, [3, 1]);
        var element62 = dom.childAt(fragment, [2]);
        var element63 = dom.childAt(element62, [3]);
        var element64 = dom.childAt(element63, [1]);
        var element65 = dom.childAt(element64, [1]);
        var element66 = dom.childAt(element65, [3]);
        var element67 = dom.childAt(element65, [5]);
        var element68 = dom.childAt(element65, [7]);
        var element69 = dom.childAt(element65, [9]);
        var element70 = dom.childAt(element65, [11]);
        var morphs = new Array(27);
        morphs[0] = dom.createAttrMorph(element58, 'class');
        morphs[1] = dom.createElementMorph(element58);
        morphs[2] = dom.createAttrMorph(element59, 'class');
        morphs[3] = dom.createElementMorph(element59);
        morphs[4] = dom.createAttrMorph(element60, 'class');
        morphs[5] = dom.createElementMorph(element60);
        morphs[6] = dom.createElementMorph(element61);
        morphs[7] = dom.createAttrMorph(element62, 'class');
        morphs[8] = dom.createMorphAt(element62,1,1);
        morphs[9] = dom.createAttrMorph(element63, 'class');
        morphs[10] = dom.createAttrMorph(element66, 'class');
        morphs[11] = dom.createElementMorph(element66);
        morphs[12] = dom.createAttrMorph(element67, 'class');
        morphs[13] = dom.createElementMorph(element67);
        morphs[14] = dom.createAttrMorph(element68, 'class');
        morphs[15] = dom.createElementMorph(element68);
        morphs[16] = dom.createAttrMorph(element69, 'class');
        morphs[17] = dom.createElementMorph(element69);
        morphs[18] = dom.createAttrMorph(element70, 'class');
        morphs[19] = dom.createElementMorph(element70);
        morphs[20] = dom.createMorphAt(element64,3,3);
        morphs[21] = dom.createMorphAt(dom.childAt(element64, [5, 3]),1,1);
        morphs[22] = dom.createMorphAt(element63,3,3);
        morphs[23] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[24] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[25] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[26] = dom.createMorphAt(fragment,10,10,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[4,106],[4,114]]]],"splitted"],[],["loc",[null,[4,102],[4,126]]]],"selected",""],[],["loc",[null,[4,81],[4,142]]]]]]],
        ["element","action",["changeView","splitted"],[],["loc",[null,[4,14],[4,48]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[5,103],[5,111]]]],"tiles"],[],["loc",[null,[5,99],[5,120]]]],"selected",""],[],["loc",[null,[5,78],[5,136]]]]]]],
        ["element","action",["changeView","tiles"],[],["loc",[null,[5,14],[5,45]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[6,102],[6,110]]]],"list"],[],["loc",[null,[6,98],[6,118]]]],"selected",""],[],["loc",[null,[6,77],[6,134]]]]]]],
        ["element","action",["changeView","list"],[],["loc",[null,[6,14],[6,44]]]],
        ["element","action",["selectCustomerForTreatment"],[],["loc",[null,[10,7],[10,47]]]],
        ["attribute","class",["concat",[["get","mainView",["loc",[null,[16,37],[16,45]]]]]]],
        ["block","if",[["get","showTopTiles",["loc",[null,[17,6],[17,18]]]]],[],0,null,["loc",[null,[17,0],[441,9]]]],
        ["attribute","class",["concat",["site-content card transaction-filter  ",["subexpr","css-bool-evaluator",[["get","showTopTiles",["loc",[null,[443,77],[443,89]]]],"","with-context-bar"],[],["loc",[null,[443,56],[443,113]]]]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showAll",["loc",[null,[448,61],[448,68]]]],"selected",""],[],["loc",[null,[448,40],[448,85]]]]]]],
        ["element","action",["toggleQuickfilter","all"],[],["loc",[null,[448,87],[448,123]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showWaiting",["loc",[null,[449,61],[449,72]]]],"selected",""],[],["loc",[null,[449,40],[449,88]]]]]]],
        ["element","action",["toggleQuickfilter","waiting"],[],["loc",[null,[449,90],[449,130]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showTreatment",["loc",[null,[450,61],[450,74]]]],"selected",""],[],["loc",[null,[450,40],[450,90]]]]]]],
        ["element","action",["toggleQuickfilter","treatment"],[],["loc",[null,[450,92],[450,134]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showPayment",["loc",[null,[451,61],[451,72]]]],"selected",""],[],["loc",[null,[451,40],[451,88]]]]]]],
        ["element","action",["toggleQuickfilter","payment"],[],["loc",[null,[451,90],[451,130]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showStation",["loc",[null,[452,61],[452,72]]]],"selected",""],[],["loc",[null,[452,40],[452,88]]]]]]],
        ["element","action",["toggleQuickfilter","station"],[],["loc",[null,[452,90],[452,130]]]],
        ["block","if",[["get","showStation",["loc",[null,[454,12],[454,23]]]]],[],1,null,["loc",[null,[454,6],[461,13]]]],
        ["block","each",[["get","users",["loc",[null,[466,20],[466,25]]]]],[],2,null,["loc",[null,[466,12],[491,21]]]],
        ["inline","treatment-patient-list",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[497,14],[497,19]]]]],[],[]],"type",["subexpr","@mut",[["get","type",["loc",[null,[498,13],[498,17]]]]],[],[]],"selectedUsers",["subexpr","@mut",[["get","selectedUsers",["loc",[null,[499,22],[499,35]]]]],[],[]],"filteredStations",["subexpr","@mut",[["get","stations",["loc",[null,[500,25],[500,33]]]]],[],[]],"changeTrigger",["subexpr","@mut",[["get","changeTrigger",["loc",[null,[501,22],[501,35]]]]],[],[]],"showAll",["subexpr","@mut",[["get","showAll",["loc",[null,[502,16],[502,23]]]]],[],[]],"showAdmission",["subexpr","@mut",[["get","showAdmission",["loc",[null,[503,22],[503,35]]]]],[],[]],"showWaiting",["subexpr","@mut",[["get","showWaiting",["loc",[null,[504,20],[504,31]]]]],[],[]],"showTreatment",["subexpr","@mut",[["get","showTreatment",["loc",[null,[505,22],[505,35]]]]],[],[]],"showPayment",["subexpr","@mut",[["get","showPayment",["loc",[null,[506,20],[506,31]]]]],[],[]],"showStation",["subexpr","@mut",[["get","showStation",["loc",[null,[507,20],[507,31]]]]],[],[]],"showStationOverride",["subexpr","is-test-mandant",[],[],["loc",[null,[508,28],[508,45]]]],"abort","abort","openTime","openTime","movePatientToWaitingRoom","movePatientToWaitingRoom","movePatientToTreatmentRoom","movePatientToTreatmentRoom","movePatientToStation","movePatientToStation","exportInvoice","openExportInvoicePanel","editTreatmentInfo","editTreatmentInfo"],["loc",[null,[496,6],[516,8]]]],
        ["inline","edit-treatment-infos-panel",[],["confirm","saveEditTreatmentInfo","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[519,67],[519,72]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[519,84],[519,94]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[519,103],[519,110]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","infoTreatmentPanel",["loc",[null,[519,126],[519,144]]]]],[],[]]],["loc",[null,[519,0],[519,146]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[525,8],[525,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[526,10],[526,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[527,13],[527,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[528,17],[528,36]]]]],[],[]]],["loc",[null,[521,0],[528,38]]]],
        ["inline","export-invoice-panel",[],["exportInvoice","openExportInvoicePanel","confirm","exportInvoice","actionReceiver",["subexpr","@mut",[["get","exportInvoicePanel",["loc",[null,[533,17],[533,35]]]]],[],[]]],["loc",[null,[530,0],[533,37]]]],
        ["inline","select-app-time",[],["save","saveDate","actionReceiver",["subexpr","@mut",[["get","selectAppTime",["loc",[null,[534,49],[534,62]]]]],[],[]]],["loc",[null,[534,0],[534,64]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});