define('jason-frontend/templates/components/patient-activities', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 110,
              "column": 6
            },
            "end": {
              "line": 113,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element68 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element68, 'class');
          morphs[1] = dom.createElementMorph(element68);
          morphs[2] = dom.createMorphAt(element68,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[111,61],[111,73]]]],"selected",""],[],["loc",[null,[111,40],[111,89]]]]]]],
          ["element","action",["toggleTag",["get","tag",["loc",[null,[112,100],[112,103]]]]],[],["loc",[null,[111,91],[112,105]]]],
          ["content","tag.name",["loc",[null,[112,106],[112,118]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 196,
                  "column": 8
                },
                "end": {
                  "line": 212,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-12/24 l-6/24@desk gutter mt20");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"class","u-mb0 field select full-width");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","title");
              var el4 = dom.createTextNode("Tier");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
              return morphs;
            },
            statements: [
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","patients",["loc",[null,[201,24],[201,32]]]]],[],[]],"value",["subexpr","@mut",[["get","filterPatient",["loc",[null,[202,22],[202,35]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","name","optionValuePath","id","allowClear",true,"name","task-patient","placeholder","Bitte wählen"],["loc",[null,[200,14],[209,16]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 227,
                  "column": 8
                },
                "end": {
                  "line": 242,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-12/24 l-6/24@desk gutter mt20");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"class","u-mb0 field select full-width");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","title");
              var el4 = dom.createTextNode("Stall");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
              return morphs;
            },
            statements: [
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","stables",["loc",[null,[232,24],[232,31]]]]],[],[]],"value",["subexpr","@mut",[["get","filterStable",["loc",[null,[233,22],[233,34]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[231,14],[239,16]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 177,
                "column": 6
              },
              "end": {
                "line": 243,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-12/24 l-6/24@desk gutter mt20");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","select--inline input-element");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","title");
            var el4 = dom.createTextNode("Kund:in");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-12/24 l-6/24@desk gutter mt20");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","u-mb0 field select full-width");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","title");
            var el4 = dom.createTextNode("Spezies");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 1]),3,3);
            morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterCustomer",["loc",[null,[182,20],[182,34]]]]],[],[]],"optionLabelPath","fullName","placeholder","Alle Kunden","allowClear",true,"typeaheadSearchingText","Suche Kunde","typeaheadNoMatchesText","Kein Kunden gefunden für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"cssClass","custom-select2 newStyle","query","queryCustomer"],["loc",[null,[181,12],[193,14]]]],
            ["block","if",[["subexpr","and",[["get","filterCustomer",["loc",[null,[196,19],[196,33]]]],["get","patients",["loc",[null,[196,34],[196,42]]]]],[],["loc",[null,[196,14],[196,43]]]]],[],0,null,["loc",[null,[196,8],[212,15]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientCategories",["loc",[null,[217,22],[217,39]]]]],[],[]],"value",["subexpr","@mut",[["get","filterPatientCategory",["loc",[null,[218,20],[218,41]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[216,12],[224,14]]]],
            ["block","if",[["subexpr","uses-stable-feature",[],[],["loc",[null,[227,14],[227,35]]]]],[],1,null,["loc",[null,[227,8],[242,15]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 116,
              "column": 4
            },
            "end": {
              "line": 244,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk gutter mt20");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","u-mb0 field select full-width");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Ärzt:in");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk gutter mt20");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","u-mb0 field select full-width");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Assistent:in");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk gutter mt20");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","u-mb0 field select full-width");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Behandlungs-Lokation");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk gutter mt20");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","u-mb0 field select full-width");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Produkt/Leistung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 1]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [7, 1]),3,3);
          morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[121,16],[121,23]]]]],[],[]],"value",["subexpr","@mut",[["get","filterDoctor",["loc",[null,[122,14],[122,26]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","shortName","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[120,6],[128,8]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[135,16],[135,26]]]]],[],[]],"value",["subexpr","@mut",[["get","filterAssistant",["loc",[null,[136,14],[136,29]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","shortName","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[134,6],[142,8]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","rooms",["loc",[null,[149,20],[149,25]]]]],[],[]],"value",["subexpr","@mut",[["get","filterRoom",["loc",[null,[150,18],[150,28]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[148,10],[156,12]]]],
          ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterArticle",["loc",[null,[163,18],[163,31]]]]],[],[]],"optionLabelPath","name","placeholder","Alle Produkte/Leistungen","allowClear",true,"typeaheadSearchingText","Suche ","typeaheadNoMatchesText","Kein Treffer für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"cssClass","custom-select2 newStyle","query","queryArticle"],["loc",[null,[162,10],[174,12]]]],
          ["block","if",[["get","showPatientInfo",["loc",[null,[177,12],[177,27]]]]],[],0,null,["loc",[null,[177,6],[243,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 249,
              "column": 4
            },
            "end": {
              "line": 251,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[250,5],[250,26]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 269,
                  "column": 16
                },
                "end": {
                  "line": 271,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element59 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element59, 'class');
              morphs[1] = dom.createMorphAt(element59,0,0);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["info-badge info-badge-very-small mr5 tag-",["get","tag.name",["loc",[null,[270,75],[270,83]]]]]]],
              ["content","tag.name",["loc",[null,[270,87],[270,99]]]]
            ],
            locals: ["tag"],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 273,
                    "column": 18
                  },
                  "end": {
                    "line": 275,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-idexx");
                var el2 = dom.createTextNode("IDEXX");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 275,
                      "column": 18
                    },
                    "end": {
                      "line": 277,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-laboklin");
                  var el2 = dom.createTextNode("Laboklin");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 277,
                        "column": 18
                      },
                      "end": {
                        "line": 279,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("label");
                    dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-intern");
                    var el2 = dom.createTextNode("Labor Intern");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                  ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 277,
                      "column": 18
                    },
                    "end": {
                      "line": 279,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.payload.privateLab",["loc",[null,[277,28],[277,51]]]]],[],0,null,["loc",[null,[277,18],[279,18]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 275,
                    "column": 18
                  },
                  "end": {
                    "line": 279,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[275,32],[275,49]]]],"laboklin"],[],["loc",[null,[275,28],[275,61]]]]],[],0,1,["loc",[null,[275,18],[279,18]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 272,
                  "column": 16
                },
                "end": {
                  "line": 280,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[273,28],[273,45]]]],"vetconnect"],[],["loc",[null,[273,24],[273,59]]]]],[],0,1,["loc",[null,[273,18],[279,25]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 291,
                      "column": 20
                    },
                    "end": {
                      "line": 295,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","is-highlighted");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" \n                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","ml0 tooltipstered symbol symbol-circle symbol-40px");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-label");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element57 = dom.childAt(fragment, [1]);
                  var element58 = dom.childAt(fragment, [3]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(element57,0,0);
                  morphs[1] = dom.createMorphAt(element57,2,2);
                  morphs[2] = dom.createAttrMorph(element58, 'title');
                  morphs[3] = dom.createAttrMorph(element58, 'style');
                  morphs[4] = dom.createMorphAt(dom.childAt(element58, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","item.payload.doctor.lastname",["loc",[null,[292,51],[292,83]]]],
                  ["content","item.payload.doctor.firstname",["loc",[null,[292,84],[292,117]]]],
                  ["attribute","title",["concat",[["get","item.payload.doctor.shortName",["loc",[null,[293,36],[293,65]]]]]]],
                  ["attribute","style",["concat",["background-color: ",["get","item.payload.doctor.color",["loc",[null,[293,155],[293,180]]]]," !important;"]]],
                  ["content","item.payload.doctor.capitals",["loc",[null,[294,48],[294,80]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 297,
                      "column": 18
                    },
                    "end": {
                      "line": 297,
                      "column": 77
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("und");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 299,
                      "column": 24
                    },
                    "end": {
                      "line": 304,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","is-highlighted");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","ml0 tooltipstered symbol symbol-circle symbol-40px");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-label");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element55 = dom.childAt(fragment, [1]);
                  var element56 = dom.childAt(fragment, [3]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(element55,0,0);
                  morphs[1] = dom.createMorphAt(element55,2,2);
                  morphs[2] = dom.createAttrMorph(element56, 'title');
                  morphs[3] = dom.createAttrMorph(element56, 'style');
                  morphs[4] = dom.createMorphAt(dom.childAt(element56, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","item.payload.assistent.lastname",["loc",[null,[300,55],[300,90]]]],
                  ["content","item.payload.assistent.firstname",["loc",[null,[300,91],[300,127]]]],
                  ["attribute","title",["concat",[["get","item.payload.assistent.shortName",["loc",[null,[300,155],[300,187]]]]]]],
                  ["attribute","style",["concat",["background-color: ",["get","item.payload.assistent.color",["loc",[null,[300,277],[300,305]]]]," !important;"]]],
                  ["content","item.payload.assistent.capitals",["loc",[null,[301,52],[301,87]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 288,
                    "column": 16
                  },
                  "end": {
                    "line": 306,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  durch\n                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-group flex-center ml0 inline-block mb0 mt0 mr0");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-group flex-center inline-block ml0 mb0 mt0 mr0");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),1,1);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.doctor",["loc",[null,[291,26],[291,45]]]]],[],0,null,["loc",[null,[291,20],[295,27]]]],
                ["block","if",[["subexpr","and",[["get","item.payload.assistent",["loc",[null,[297,29],[297,51]]]],["get","item.payload.doctor",["loc",[null,[297,52],[297,71]]]]],[],["loc",[null,[297,24],[297,72]]]]],[],1,null,["loc",[null,[297,18],[297,84]]]],
                ["block","if",[["get","item.payload.assistent",["loc",[null,[299,30],[299,52]]]]],[],2,null,["loc",[null,[299,24],[304,31]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 307,
                    "column": 16
                  },
                  "end": {
                    "line": 307,
                    "column": 111
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" durch ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","ml5 is-highlighted");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","item.eltigaMandant",["loc",[null,[307,82],[307,104]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 286,
                  "column": 14
                },
                "end": {
                  "line": 308,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                Behandlung\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","or",[["get","item.payload.assistent",["loc",[null,[288,26],[288,48]]]],["get","item.payload.doctor",["loc",[null,[288,49],[288,68]]]]],[],["loc",[null,[288,22],[288,69]]]]],[],0,null,["loc",[null,[288,16],[306,23]]]],
              ["block","if",[["get","item.eltigaMandant",["loc",[null,[307,22],[307,40]]]]],[],1,null,["loc",[null,[307,16],[307,118]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 308,
                    "column": 14
                  },
                  "end": {
                    "line": 310,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Erinnerung\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 310,
                      "column": 14
                    },
                    "end": {
                      "line": 312,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                Notiz\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 312,
                        "column": 14
                      },
                      "end": {
                        "line": 314,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                Labor\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 314,
                          "column": 14
                        },
                        "end": {
                          "line": 316,
                          "column": 14
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                Bildgebung\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 316,
                            "column": 14
                          },
                          "end": {
                            "line": 318,
                            "column": 14
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                Rechnung\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 318,
                              "column": 14
                            },
                            "end": {
                              "line": 320,
                              "column": 14
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                Impfung\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes() { return []; },
                        statements: [

                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 320,
                                "column": 14
                              },
                              "end": {
                                "line": 322,
                                "column": 14
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                E-Mail Weiterleitung\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes() { return []; },
                          statements: [

                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        var child0 = (function() {
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 322,
                                  "column": 14
                                },
                                "end": {
                                  "line": 324,
                                  "column": 14
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("                eltiga Nachricht\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes() { return []; },
                            statements: [

                            ],
                            locals: [],
                            templates: []
                          };
                        }());
                        var child1 = (function() {
                          var child0 = (function() {
                            return {
                              meta: {
                                "revision": "Ember@1.13.5",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 324,
                                    "column": 14
                                  },
                                  "end": {
                                    "line": 326,
                                    "column": 14
                                  }
                                },
                                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                              },
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createTextNode("                Dokument\n");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes() { return []; },
                              statements: [

                              ],
                              locals: [],
                              templates: []
                            };
                          }());
                          var child1 = (function() {
                            var child0 = (function() {
                              return {
                                meta: {
                                  "revision": "Ember@1.13.5",
                                  "loc": {
                                    "source": null,
                                    "start": {
                                      "line": 326,
                                      "column": 14
                                    },
                                    "end": {
                                      "line": 328,
                                      "column": 14
                                    }
                                  },
                                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                                },
                                arity: 0,
                                cachedFragment: null,
                                hasRendered: false,
                                buildFragment: function buildFragment(dom) {
                                  var el0 = dom.createDocumentFragment();
                                  var el1 = dom.createTextNode("                Termin\n              ");
                                  dom.appendChild(el0, el1);
                                  return el0;
                                },
                                buildRenderNodes: function buildRenderNodes() { return []; },
                                statements: [

                                ],
                                locals: [],
                                templates: []
                              };
                            }());
                            return {
                              meta: {
                                "revision": "Ember@1.13.5",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 326,
                                    "column": 14
                                  },
                                  "end": {
                                    "line": 328,
                                    "column": 14
                                  }
                                },
                                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                              },
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createComment("");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                var morphs = new Array(1);
                                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                                dom.insertBoundary(fragment, 0);
                                dom.insertBoundary(fragment, null);
                                return morphs;
                              },
                              statements: [
                                ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[326,28],[326,37]]]],"app"],[],["loc",[null,[326,24],[326,44]]]]],[],0,null,["loc",[null,[326,14],[328,14]]]]
                              ],
                              locals: [],
                              templates: [child0]
                            };
                          }());
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 324,
                                  "column": 14
                                },
                                "end": {
                                  "line": 328,
                                  "column": 14
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createComment("");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                              dom.insertBoundary(fragment, 0);
                              dom.insertBoundary(fragment, null);
                              return morphs;
                            },
                            statements: [
                              ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[324,28],[324,37]]]],"doc"],[],["loc",[null,[324,24],[324,44]]]]],[],0,1,["loc",[null,[324,14],[328,14]]]]
                            ],
                            locals: [],
                            templates: [child0, child1]
                          };
                        }());
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 322,
                                "column": 14
                              },
                              "end": {
                                "line": 328,
                                "column": 14
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                            dom.insertBoundary(fragment, 0);
                            dom.insertBoundary(fragment, null);
                            return morphs;
                          },
                          statements: [
                            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[322,28],[322,37]]]],"com"],[],["loc",[null,[322,24],[322,44]]]]],[],0,1,["loc",[null,[322,14],[328,14]]]]
                          ],
                          locals: [],
                          templates: [child0, child1]
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 320,
                              "column": 14
                            },
                            "end": {
                              "line": 328,
                              "column": 14
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[320,28],[320,37]]]],"mail"],[],["loc",[null,[320,24],[320,45]]]]],[],0,1,["loc",[null,[320,14],[328,14]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 318,
                            "column": 14
                          },
                          "end": {
                            "line": 328,
                            "column": 14
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[318,28],[318,37]]]],"vacc"],[],["loc",[null,[318,24],[318,45]]]]],[],0,1,["loc",[null,[318,14],[328,14]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 316,
                          "column": 14
                        },
                        "end": {
                          "line": 328,
                          "column": 14
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[316,28],[316,37]]]],"invoice"],[],["loc",[null,[316,24],[316,48]]]]],[],0,1,["loc",[null,[316,14],[328,14]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 314,
                        "column": 14
                      },
                      "end": {
                        "line": 328,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[314,28],[314,37]]]],"img"],[],["loc",[null,[314,24],[314,44]]]]],[],0,1,["loc",[null,[314,14],[328,14]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 312,
                      "column": 14
                    },
                    "end": {
                      "line": 328,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[312,28],[312,37]]]],"lab"],[],["loc",[null,[312,24],[312,44]]]]],[],0,1,["loc",[null,[312,14],[328,14]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 310,
                    "column": 14
                  },
                  "end": {
                    "line": 328,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[310,28],[310,37]]]],"not"],[],["loc",[null,[310,24],[310,44]]]]],[],0,1,["loc",[null,[310,14],[328,14]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 308,
                  "column": 14
                },
                "end": {
                  "line": 328,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[308,28],[308,37]]]],"rem"],[],["loc",[null,[308,24],[308,44]]]]],[],0,1,["loc",[null,[308,14],[328,14]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 331,
                    "column": 84
                  },
                  "end": {
                    "line": 331,
                    "column": 236
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.patient.customer.firstname",["loc",[null,[331,166],[331,201]]]],
                ["content","item.patient.customer.lastname",["loc",[null,[331,202],[331,236]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 331,
                    "column": 253
                  },
                  "end": {
                    "line": 331,
                    "column": 346
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.patient.name",["loc",[null,[331,325],[331,346]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 331,
                    "column": 359
                  },
                  "end": {
                    "line": 331,
                    "column": 457
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","assets/images/eltiga-poe.png");
                dom.setAttribute(el1,"style","max-height: 18px");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 330,
                  "column": 12
                },
                "end": {
                  "line": 332,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","mtm10 mb10 d-flex align-items-center flex-gap-5");
              var el2 = dom.createTextNode("Kund:in: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" mit ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element54 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element54,1,1);
              morphs[1] = dom.createMorphAt(element54,3,3);
              morphs[2] = dom.createMorphAt(element54,5,5);
              return morphs;
            },
            statements: [
              ["block","link-to",["customers.edit",["get","item.patient.customer.id",["loc",[null,[331,112],[331,136]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[331,84],[331,248]]]],
              ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[331,280],[331,295]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[331,253],[331,358]]]],
              ["block","if",[["get","item.patient.etigaActive",["loc",[null,[331,365],[331,389]]]]],[],2,null,["loc",[null,[331,359],[331,464]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child5 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 337,
                    "column": 14
                  },
                  "end": {
                    "line": 339,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Von Kund:in\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 340,
                    "column": 14
                  },
                  "end": {
                    "line": 342,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Von Praxis\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 343,
                    "column": 14
                  },
                  "end": {
                    "line": 345,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Von Praxis\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 335,
                  "column": 10
                },
                "end": {
                  "line": 350,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element52 = dom.childAt(fragment, [1]);
              var element53 = dom.childAt(fragment, [3]);
              var morphs = new Array(6);
              morphs[0] = dom.createAttrMorph(element52, 'class');
              morphs[1] = dom.createMorphAt(element52,1,1);
              morphs[2] = dom.createMorphAt(element52,2,2);
              morphs[3] = dom.createMorphAt(element52,3,3);
              morphs[4] = dom.createAttrMorph(element53, 'class');
              morphs[5] = dom.createUnsafeMorphAt(element53,1,1);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["message-sender mt20 ",["get","item.title",["loc",[null,[336,46],[336,56]]]]]]],
              ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[337,24],[337,34]]]],"msg_incoming"],[],["loc",[null,[337,20],[337,50]]]]],[],0,null,["loc",[null,[337,14],[339,21]]]],
              ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[340,24],[340,34]]]],"reminder"],[],["loc",[null,[340,20],[340,46]]]]],[],1,null,["loc",[null,[340,14],[342,21]]]],
              ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[343,24],[343,34]]]],"msg_outgoing"],[],["loc",[null,[343,20],[343,50]]]]],[],2,null,["loc",[null,[343,14],[345,21]]]],
              ["attribute","class",["concat",["message ",["get","item.title",["loc",[null,[347,34],[347,44]]]]]]],
              ["content","item.payload.text",["loc",[null,[348,14],[348,37]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 351,
                  "column": 10
                },
                "end": {
                  "line": 361,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element50 = dom.childAt(fragment, [1]);
              var element51 = dom.childAt(element50, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element50, [1]),1,1);
              morphs[1] = dom.createElementMorph(element51);
              morphs[2] = dom.createMorphAt(element51,1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[354,16],[354,39]]]],
              ["element","action",["openApp",["get","item.referenceId",["loc",[null,[356,36],[356,52]]]]],[],["loc",[null,[356,17],[356,54]]]],
              ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[358,16],[358,71]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child7 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 362,
                  "column": 10
                },
                "end": {
                  "line": 372,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element48 = dom.childAt(fragment, [1]);
              var element49 = dom.childAt(element48, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element48, [1]),1,1);
              morphs[1] = dom.createElementMorph(element49);
              morphs[2] = dom.createMorphAt(element49,1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[365,16],[365,39]]]],
              ["element","action",["editNote",["get","item.referenceId",["loc",[null,[367,37],[367,53]]]],["get","item.patient.id",["loc",[null,[367,54],[367,69]]]]],[],["loc",[null,[367,17],[367,71]]]],
              ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[369,16],[369,71]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child8 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 373,
                  "column": 10
                },
                "end": {
                  "line": 379,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[376,16],[376,39]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child9 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 380,
                  "column": 10
                },
                "end": {
                  "line": 386,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[383,16],[383,39]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child10 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 393,
                    "column": 18
                  },
                  "end": {
                    "line": 395,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","label label-danger");
                var el2 = dom.createTextNode("Storniert");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 395,
                    "column": 18
                  },
                  "end": {
                    "line": 397,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","invoice-paymentstatus",[],["paymentStatus",["subexpr","@mut",[["get","item.payload.paymentStatus",["loc",[null,[396,58],[396,84]]]]],[],[]]],["loc",[null,[396,20],[396,86]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 402,
                      "column": 20
                    },
                    "end": {
                      "line": 404,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","entry.patient",["loc",[null,[403,25],[403,42]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 410,
                          "column": 57
                        },
                        "end": {
                          "line": 411,
                          "column": 113
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[411,97],[411,113]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 412,
                          "column": 28
                        },
                        "end": {
                          "line": 412,
                          "column": 202
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode(" ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","ml5 mr5");
                      var el2 = dom.createTextNode("-");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[412,146],[412,173]]]],"Verabreicht","Angewandt"],[],["loc",[null,[412,126],[412,200]]]]],["loc",[null,[412,82],[412,202]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 408,
                        "column": 26
                      },
                      "end": {
                        "line": 417,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","d-flex align-items-center");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" x ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2,"class","ml5 mr5");
                    var el3 = dom.createTextNode("-");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element42 = dom.childAt(fragment, [1]);
                    var element43 = dom.childAt(element42, [1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(element43,0,0);
                    morphs[1] = dom.createMorphAt(element43,2,2);
                    morphs[2] = dom.createMorphAt(element42,3,3);
                    morphs[3] = dom.createMorphAt(dom.childAt(element42, [7]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","article.quantity",["loc",[null,[410,34],[410,54]]]],
                    ["block","link-to",["services.edit",["get","article.id",["loc",[null,[410,84],[410,94]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[410,57],[411,125]]]],
                    ["block","if",[["get","article.applied",["loc",[null,[412,34],[412,49]]]]],[],1,null,["loc",[null,[412,28],[412,209]]]],
                    ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[415,54],[415,67]]]]],[],[]]],["loc",[null,[414,34],[415,69]]]]
                  ],
                  locals: ["article"],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 406,
                      "column": 24
                    },
                    "end": {
                      "line": 418,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Verrechnete Leistungen:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.services",["loc",[null,[408,34],[408,48]]]]],[],0,null,["loc",[null,[408,26],[417,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 423,
                          "column": 57
                        },
                        "end": {
                          "line": 424,
                          "column": 113
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[424,97],[424,113]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 425,
                          "column": 30
                        },
                        "end": {
                          "line": 425,
                          "column": 204
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode(" ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","ml5 mr5");
                      var el2 = dom.createTextNode("-");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[425,148],[425,175]]]],"Verabreicht","Angewandt"],[],["loc",[null,[425,128],[425,202]]]]],["loc",[null,[425,84],[425,204]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 421,
                        "column": 26
                      },
                      "end": {
                        "line": 430,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","d-flex align-items-center");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" x ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2,"class","ml5 mr5");
                    var el3 = dom.createTextNode("-");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element40 = dom.childAt(fragment, [1]);
                    var element41 = dom.childAt(element40, [1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(element41,0,0);
                    morphs[1] = dom.createMorphAt(element41,2,2);
                    morphs[2] = dom.createMorphAt(element40,3,3);
                    morphs[3] = dom.createMorphAt(dom.childAt(element40, [7]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","article.quantity",["loc",[null,[423,34],[423,54]]]],
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[423,84],[423,94]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[423,57],[424,125]]]],
                    ["block","if",[["get","article.applied",["loc",[null,[425,36],[425,51]]]]],[],1,null,["loc",[null,[425,30],[425,211]]]],
                    ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[428,58],[428,71]]]]],[],[]]],["loc",[null,[427,36],[428,73]]]]
                  ],
                  locals: ["article"],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 419,
                      "column": 24
                    },
                    "end": {
                      "line": 431,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Verrechnete Produkte:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.products",["loc",[null,[421,34],[421,48]]]]],[],0,null,["loc",[null,[421,26],[430,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child3 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 436,
                          "column": 57
                        },
                        "end": {
                          "line": 437,
                          "column": 113
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[437,97],[437,113]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 438,
                          "column": 30
                        },
                        "end": {
                          "line": 438,
                          "column": 204
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode(" ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","ml5 mr5");
                      var el2 = dom.createTextNode("-");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[438,148],[438,175]]]],"Verabreicht","Angewandt"],[],["loc",[null,[438,128],[438,202]]]]],["loc",[null,[438,84],[438,204]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 434,
                        "column": 26
                      },
                      "end": {
                        "line": 443,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","d-flex align-items-center");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" x ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2,"class","ml5 mr5");
                    var el3 = dom.createTextNode("-");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element38 = dom.childAt(fragment, [1]);
                    var element39 = dom.childAt(element38, [1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(element39,0,0);
                    morphs[1] = dom.createMorphAt(element39,2,2);
                    morphs[2] = dom.createMorphAt(element38,3,3);
                    morphs[3] = dom.createMorphAt(dom.childAt(element38, [7]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","article.quantity",["loc",[null,[436,34],[436,54]]]],
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[436,84],[436,94]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[436,57],[437,125]]]],
                    ["block","if",[["get","article.applied",["loc",[null,[438,36],[438,51]]]]],[],1,null,["loc",[null,[438,30],[438,211]]]],
                    ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[441,58],[441,71]]]]],[],[]]],["loc",[null,[440,36],[441,73]]]]
                  ],
                  locals: ["article"],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 432,
                      "column": 24
                    },
                    "end": {
                      "line": 444,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Verrechnete Medikamente:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.meds",["loc",[null,[434,34],[434,44]]]]],[],0,null,["loc",[null,[434,26],[443,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child4 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 449,
                          "column": 57
                        },
                        "end": {
                          "line": 450,
                          "column": 113
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[450,97],[450,113]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 451,
                          "column": 30
                        },
                        "end": {
                          "line": 451,
                          "column": 204
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode(" ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","ml5 mr5");
                      var el2 = dom.createTextNode("-");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[451,148],[451,175]]]],"Verabreicht","Angewandt"],[],["loc",[null,[451,128],[451,202]]]]],["loc",[null,[451,84],[451,204]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 447,
                        "column": 26
                      },
                      "end": {
                        "line": 456,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","d-flex align-items-center");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" x ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2,"class","ml5 mr5");
                    var el3 = dom.createTextNode("-");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element36 = dom.childAt(fragment, [1]);
                    var element37 = dom.childAt(element36, [1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(element37,0,0);
                    morphs[1] = dom.createMorphAt(element37,2,2);
                    morphs[2] = dom.createMorphAt(element36,3,3);
                    morphs[3] = dom.createMorphAt(dom.childAt(element36, [7]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","article.quantity",["loc",[null,[449,34],[449,54]]]],
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[449,84],[449,94]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[449,57],[450,125]]]],
                    ["block","if",[["get","article.applied",["loc",[null,[451,36],[451,51]]]]],[],1,null,["loc",[null,[451,30],[451,211]]]],
                    ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[454,58],[454,71]]]]],[],[]]],["loc",[null,[453,36],[454,73]]]]
                  ],
                  locals: ["article"],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 445,
                      "column": 24
                    },
                    "end": {
                      "line": 457,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Verrechnete Ernährung:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.feeding",["loc",[null,[447,34],[447,47]]]]],[],0,null,["loc",[null,[447,26],[456,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child5 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 461,
                          "column": 28
                        },
                        "end": {
                          "line": 466,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("img");
                      dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                      dom.setAttribute(el2,"class","mr5");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2,"class","is-highlighted cursor");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                              ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element35 = dom.childAt(fragment, [1, 3]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element35);
                      morphs[1] = dom.createMorphAt(element35,0,0);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["gotoTemplate",["get","item.referenceId",["loc",[null,[464,89],[464,105]]]],["get","template.id",["loc",[null,[464,106],[464,117]]]],"invoice",["get","entry.patientId",["loc",[null,[464,128],[464,143]]]]],[],["loc",[null,[464,65],[464,145]]]],
                      ["content","template.name",["loc",[null,[464,146],[464,163]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 460,
                        "column": 26
                      },
                      "end": {
                        "line": 467,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","template.name",["loc",[null,[461,34],[461,47]]]]],[],0,null,["loc",[null,[461,28],[466,35]]]]
                  ],
                  locals: ["template"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 458,
                      "column": 24
                    },
                    "end": {
                      "line": 468,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Vorlagen:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.templates",["loc",[null,[460,34],[460,49]]]]],[],0,null,["loc",[null,[460,26],[467,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 401,
                    "column": 18
                  },
                  "end": {
                    "line": 470,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","pl5 pt5 pb10");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element44 = dom.childAt(fragment, [2]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(element44,1,1);
                morphs[2] = dom.createMorphAt(element44,2,2);
                morphs[3] = dom.createMorphAt(element44,3,3);
                morphs[4] = dom.createMorphAt(element44,4,4);
                morphs[5] = dom.createMorphAt(element44,5,5);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["block","if",[["get","entry.patientId",["loc",[null,[402,26],[402,41]]]]],[],0,null,["loc",[null,[402,20],[404,27]]]],
                ["block","if",[["get","entry.services",["loc",[null,[406,30],[406,44]]]]],[],1,null,["loc",[null,[406,24],[418,31]]]],
                ["block","if",[["get","entry.products",["loc",[null,[419,30],[419,44]]]]],[],2,null,["loc",[null,[419,24],[431,31]]]],
                ["block","if",[["get","entry.meds",["loc",[null,[432,30],[432,40]]]]],[],3,null,["loc",[null,[432,24],[444,31]]]],
                ["block","if",[["get","entry.feeding",["loc",[null,[445,30],[445,43]]]]],[],4,null,["loc",[null,[445,24],[457,31]]]],
                ["block","if",[["get","entry.templates",["loc",[null,[458,30],[458,45]]]]],[],5,null,["loc",[null,[458,24],[468,31]]]]
              ],
              locals: ["entry"],
              templates: [child0, child1, child2, child3, child4, child5]
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 474,
                    "column": 14
                  },
                  "end": {
                    "line": 477,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[476,16],[476,71]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 387,
                  "column": 10
                },
                "end": {
                  "line": 479,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","d-flex align-items-center");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","mr10");
              var el5 = dom.createElement("b");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode(",");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","mr10");
              var el5 = dom.createTextNode("Gesamtsumme: ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode(" EUR");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element45 = dom.childAt(fragment, [1]);
              var element46 = dom.childAt(element45, [1]);
              var element47 = dom.childAt(element46, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element47, [1, 0]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element47, [3]),1,1);
              morphs[2] = dom.createMorphAt(element47,5,5);
              morphs[3] = dom.createMorphAt(dom.childAt(element46, [3]),1,1);
              morphs[4] = dom.createMorphAt(element45,3,3);
              return morphs;
            },
            statements: [
              ["content","item.payload.code",["loc",[null,[391,40],[391,61]]]],
              ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","item.payload.total",["loc",[null,[392,94],[392,112]]]]],[],[]]],["loc",[null,[392,50],[392,114]]]],
              ["block","if",[["subexpr","eq",[["get","item.payload.status",["loc",[null,[393,28],[393,47]]]],"canceled"],[],["loc",[null,[393,24],[393,59]]]]],[],0,1,["loc",[null,[393,18],[397,25]]]],
              ["block","each",[["get","item.payload.entries",["loc",[null,[401,26],[401,46]]]]],[],2,null,["loc",[null,[401,18],[470,27]]]],
              ["block","link-to",["invoices.show",["get","item.referenceId",["loc",[null,[474,41],[474,57]]]]],["classNames","icon-button icon-button--default list-action-square exp line-action align-items-center"],3,null,["loc",[null,[474,14],[477,26]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        var child11 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 484,
                      "column": 18
                    },
                    "end": {
                      "line": 486,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    Eine eltiga App Benutzer:in hat das Dokument ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","is-highlighted cursor");
                  var el2 = dom.createElement("b");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" geteilt.\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element32 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element32);
                  morphs[1] = dom.createMorphAt(dom.childAt(element32, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[485,122],[485,138]]]]],[],["loc",[null,[485,98],[485,140]]]],
                  ["content","item.payload.filename",["loc",[null,[485,144],[485,169]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 486,
                      "column": 18
                    },
                    "end": {
                      "line": 488,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    Die Praxis hat das Dokument ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","is-highlighted cursor");
                  var el2 = dom.createElement("b");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" hinzugefügt.\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element31 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element31);
                  morphs[1] = dom.createMorphAt(dom.childAt(element31, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[487,105],[487,121]]]]],[],["loc",[null,[487,81],[487,123]]]],
                  ["content","item.payload.filename",["loc",[null,[487,127],[487,152]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 483,
                    "column": 16
                  },
                  "end": {
                    "line": 489,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.payload.ctx",["loc",[null,[484,28],[484,44]]]],"eltiga"],[],["loc",[null,[484,24],[484,54]]]]],[],0,1,["loc",[null,[484,18],[488,25]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 489,
                    "column": 16
                  },
                  "end": {
                    "line": 491,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","item.payload.text",["loc",[null,[490,18],[490,41]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 480,
                  "column": 10
                },
                "end": {
                  "line": 498,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1 fw-semibold");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action align-items-center");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element33 = dom.childAt(fragment, [1]);
              var element34 = dom.childAt(element33, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element33, [1]),1,1);
              morphs[1] = dom.createElementMorph(element34);
              morphs[2] = dom.createMorphAt(element34,1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.payload.filename",["loc",[null,[483,22],[483,43]]]]],[],0,1,["loc",[null,[483,16],[491,23]]]],
              ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[493,41],[493,57]]]],["get","item.patient.id",["loc",[null,[493,58],[493,73]]]]],[],["loc",[null,[493,17],[493,75]]]],
              ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[495,16],[495,71]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child12 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 499,
                  "column": 10
                },
                "end": {
                  "line": 507,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"class","mr10");
              dom.setAttribute(el2,"alt","");
              dom.setAttribute(el2,"style","width: 22px;");
              dom.setAttribute(el2,"src","assets/images/icons/history/vacc.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1 fw-semibold");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[504,16],[504,37]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child13 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 514,
                    "column": 16
                  },
                  "end": {
                    "line": 515,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 515,
                      "column": 16
                    },
                    "end": {
                      "line": 516,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 516,
                        "column": 16
                      },
                      "end": {
                        "line": 517,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 517,
                        "column": 16
                      },
                      "end": {
                        "line": 519,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","item.payload.orderTypeString",["loc",[null,[518,18],[518,50]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 516,
                      "column": 16
                    },
                    "end": {
                      "line": 519,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.payload.privateLab",["loc",[null,[516,26],[516,49]]]]],[],0,1,["loc",[null,[516,16],[519,16]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 515,
                    "column": 16
                  },
                  "end": {
                    "line": 519,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[515,30],[515,47]]]],"laboklin"],[],["loc",[null,[515,26],[515,59]]]]],[],0,1,["loc",[null,[515,16],[519,16]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 519,
                    "column": 24
                  },
                  "end": {
                    "line": 519,
                    "column": 132
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","block small");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","laboklin-reporttype",[],["type",["subexpr","@mut",[["get","form.reportType",["loc",[null,[519,108],[519,123]]]]],[],[]]],["loc",[null,[519,81],[519,125]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 508,
                  "column": 10
                },
                "end": {
                  "line": 529,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"class","mr10");
              dom.setAttribute(el2,"alt","");
              dom.setAttribute(el2,"style","width: 30px;");
              dom.setAttribute(el2,"src","assets/images/icons/history/lab.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1 fw-semibold");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element29 = dom.childAt(fragment, [1]);
              var element30 = dom.childAt(element29, [9]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element29,3,3);
              morphs[1] = dom.createMorphAt(element29,5,5);
              morphs[2] = dom.createUnsafeMorphAt(dom.childAt(element29, [7]),1,1);
              morphs[3] = dom.createElementMorph(element30);
              morphs[4] = dom.createMorphAt(element30,1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[514,26],[514,43]]]],"vetconnect"],[],["loc",[null,[514,22],[514,57]]]]],[],0,1,["loc",[null,[514,16],[519,23]]]],
              ["block","if",[["get","item.payload.reportType",["loc",[null,[519,30],[519,53]]]]],[],2,null,["loc",[null,[519,24],[519,139]]]],
              ["content","item.payload.text",["loc",[null,[522,16],[522,39]]]],
              ["element","action",["openLab",["get","item.referenceId",["loc",[null,[524,36],[524,52]]]],["get","item.patient.id",["loc",[null,[524,53],[524,68]]]]],[],["loc",[null,[524,17],[524,70]]]],
              ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[526,16],[526,71]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child14 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 530,
                  "column": 10
                },
                "end": {
                  "line": 542,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center img detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"class","mr10");
              dom.setAttribute(el2,"alt","");
              dom.setAttribute(el2,"style","width: 30px;");
              dom.setAttribute(el2,"src","assets/images/icons/history/img.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1 fw-semibold");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element27 = dom.childAt(fragment, [1]);
              var element28 = dom.childAt(element27, [5]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element27, [3]),1,1);
              morphs[1] = dom.createElementMorph(element28);
              morphs[2] = dom.createMorphAt(element28,1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[535,16],[535,37]]]],
              ["element","action",["openLab",["get","item.referenceId",["loc",[null,[537,38],[537,54]]]]],[],["loc",[null,[537,19],[537,56]]]],
              ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[539,18],[539,73]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child15 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 546,
                    "column": 16
                  },
                  "end": {
                    "line": 548,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Anamnese:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.medicalHistory",["loc",[null,[547,65],[547,98]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 549,
                    "column": 16
                  },
                  "end": {
                    "line": 551,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Symptome:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.sypmtoms",["loc",[null,[550,65],[550,92]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 552,
                    "column": 16
                  },
                  "end": {
                    "line": 554,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Diagnose:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.diagnosis",["loc",[null,[553,65],[553,93]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 555,
                    "column": 16
                  },
                  "end": {
                    "line": 557,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Therapie:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.therapy",["loc",[null,[556,65],[556,91]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 558,
                    "column": 16
                  },
                  "end": {
                    "line": 560,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Beratung:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.report",["loc",[null,[559,65],[559,90]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 563,
                      "column": 20
                    },
                    "end": {
                      "line": 563,
                      "column": 145
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"style","font-style: italic;font-weight:normal");
                  var el2 = dom.createTextNode("\"");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\"");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
                  return morphs;
                },
                statements: [
                  ["content","item.payload.ratingComment",["loc",[null,[563,107],[563,137]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 561,
                    "column": 16
                  },
                  "end": {
                    "line": 565,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Feedback durch Kund:in:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element24 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element24,1,1);
                morphs[1] = dom.createMorphAt(element24,3,3);
                return morphs;
              },
              statements: [
                ["inline","rating-status",[],["rating",["subexpr","@mut",[["get","item.payload.rating",["loc",[null,[562,102],[562,121]]]]],[],[]],"classNames","inline-block"],["loc",[null,[562,79],[562,149]]]],
                ["block","if",[["get","item.payload.ratingComment",["loc",[null,[563,26],[563,52]]]]],[],0,null,["loc",[null,[563,20],[563,152]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 566,
                    "column": 16
                  },
                  "end": {
                    "line": 568,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Tierärztliche Leistungen:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.manualTreatment",["loc",[null,[567,81],[567,115]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child7 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 573,
                        "column": 49
                      },
                      "end": {
                        "line": 573,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[573,74],[573,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 573,
                          "column": 98
                        },
                        "end": {
                          "line": 574,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[574,138],[574,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 573,
                        "column": 90
                      },
                      "end": {
                        "line": 574,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["services.edit",["get","article.id",["loc",[null,[573,125],[573,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[573,98],[574,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 575,
                        "column": 24
                      },
                      "end": {
                        "line": 575,
                        "column": 198
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[575,142],[575,169]]]],"Verabreicht","Angewandt"],[],["loc",[null,[575,122],[575,196]]]]],["loc",[null,[575,78],[575,198]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 576,
                        "column": 24
                      },
                      "end": {
                        "line": 577,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                        ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element21 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element21);
                    morphs[1] = dom.createMorphAt(element21,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[576,121],[576,135]]]],["get","article.id",["loc",[null,[576,136],[576,146]]]]],[],["loc",[null,[576,93],[576,148]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[576,149],[576,272]]]],
                    ["content","article.dosingInfo",["loc",[null,[576,283],[576,305]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 571,
                      "column": 18
                    },
                    "end": {
                      "line": 578,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element22 = dom.childAt(fragment, [1]);
                  var element23 = dom.childAt(element22, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element23,0,0);
                  morphs[1] = dom.createMorphAt(element23,2,2);
                  morphs[2] = dom.createMorphAt(element22,3,3);
                  morphs[3] = dom.createMorphAt(element22,5,5);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[573,26],[573,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[573,55],[573,72]]]]],[],0,1,["loc",[null,[573,49],[574,173]]]],
                  ["block","if",[["get","article.applied",["loc",[null,[575,30],[575,45]]]]],[],2,null,["loc",[null,[575,24],[575,205]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[576,30],[576,44]]]]],[],3,null,["loc",[null,[576,24],[577,31]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2, child3]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 569,
                    "column": 16
                  },
                  "end": {
                    "line": 579,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","block mb5 mt10 small");
                var el2 = dom.createTextNode("Erhaltene Leistungen:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.services",["loc",[null,[571,26],[571,47]]]]],[],0,null,["loc",[null,[571,18],[578,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child8 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 584,
                        "column": 49
                      },
                      "end": {
                        "line": 584,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[584,74],[584,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 584,
                          "column": 98
                        },
                        "end": {
                          "line": 585,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[585,138],[585,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 584,
                        "column": 90
                      },
                      "end": {
                        "line": 585,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[584,125],[584,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[584,98],[585,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 586,
                        "column": 22
                      },
                      "end": {
                        "line": 586,
                        "column": 196
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[586,140],[586,167]]]],"Verabreicht","Angewandt"],[],["loc",[null,[586,120],[586,194]]]]],["loc",[null,[586,76],[586,196]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 587,
                        "column": 22
                      },
                      "end": {
                        "line": 588,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                  ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element18 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element18);
                    morphs[1] = dom.createMorphAt(element18,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[587,119],[587,133]]]],["get","article.id",["loc",[null,[587,134],[587,144]]]]],[],["loc",[null,[587,91],[587,146]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[587,147],[587,270]]]],
                    ["content","article.dosingInfo",["loc",[null,[587,281],[587,303]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 582,
                      "column": 18
                    },
                    "end": {
                      "line": 589,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element19 = dom.childAt(fragment, [1]);
                  var element20 = dom.childAt(element19, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element20,0,0);
                  morphs[1] = dom.createMorphAt(element20,2,2);
                  morphs[2] = dom.createMorphAt(element19,3,3);
                  morphs[3] = dom.createMorphAt(element19,5,5);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[584,26],[584,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[584,55],[584,72]]]]],[],0,1,["loc",[null,[584,49],[585,173]]]],
                  ["block","if",[["get","article.applied",["loc",[null,[586,28],[586,43]]]]],[],2,null,["loc",[null,[586,22],[586,203]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[587,28],[587,42]]]]],[],3,null,["loc",[null,[587,22],[588,25]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2, child3]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 580,
                    "column": 16
                  },
                  "end": {
                    "line": 590,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","block mb5 mt10 small");
                var el2 = dom.createTextNode("Erhaltene Produkte:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.products",["loc",[null,[582,26],[582,47]]]]],[],0,null,["loc",[null,[582,18],[589,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child9 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 595,
                        "column": 49
                      },
                      "end": {
                        "line": 595,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[595,74],[595,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 595,
                          "column": 98
                        },
                        "end": {
                          "line": 596,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[596,138],[596,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 595,
                        "column": 90
                      },
                      "end": {
                        "line": 596,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[595,125],[595,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[595,98],[596,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 597,
                        "column": 22
                      },
                      "end": {
                        "line": 597,
                        "column": 196
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[597,140],[597,167]]]],"Verabreicht","Angewandt"],[],["loc",[null,[597,120],[597,194]]]]],["loc",[null,[597,76],[597,196]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 598,
                        "column": 22
                      },
                      "end": {
                        "line": 599,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                      ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element15 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element15);
                    morphs[1] = dom.createMorphAt(element15,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[598,119],[598,133]]]],["get","article.id",["loc",[null,[598,134],[598,144]]]]],[],["loc",[null,[598,91],[598,146]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[598,147],[598,270]]]],
                    ["content","article.dosingInfo",["loc",[null,[598,281],[598,303]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 593,
                      "column": 18
                    },
                    "end": {
                      "line": 600,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element16 = dom.childAt(fragment, [1]);
                  var element17 = dom.childAt(element16, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element17,0,0);
                  morphs[1] = dom.createMorphAt(element17,2,2);
                  morphs[2] = dom.createMorphAt(element16,3,3);
                  morphs[3] = dom.createMorphAt(element16,5,5);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[595,26],[595,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[595,55],[595,72]]]]],[],0,1,["loc",[null,[595,49],[596,173]]]],
                  ["block","if",[["get","article.applied",["loc",[null,[597,28],[597,43]]]]],[],2,null,["loc",[null,[597,22],[597,203]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[598,28],[598,42]]]]],[],3,null,["loc",[null,[598,22],[599,29]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2, child3]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 591,
                    "column": 16
                  },
                  "end": {
                    "line": 601,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","block mb5 mt10 small");
                var el2 = dom.createTextNode("Erhaltene Medikamente:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.meds",["loc",[null,[593,26],[593,43]]]]],[],0,null,["loc",[null,[593,18],[600,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child10 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 606,
                        "column": 49
                      },
                      "end": {
                        "line": 606,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[606,74],[606,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 606,
                          "column": 98
                        },
                        "end": {
                          "line": 607,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[607,138],[607,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 606,
                        "column": 90
                      },
                      "end": {
                        "line": 607,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[606,125],[606,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[606,98],[607,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 608,
                        "column": 24
                      },
                      "end": {
                        "line": 608,
                        "column": 198
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[608,142],[608,169]]]],"Verabreicht","Angewandt"],[],["loc",[null,[608,122],[608,196]]]]],["loc",[null,[608,78],[608,198]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 609,
                        "column": 24
                      },
                      "end": {
                        "line": 612,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n\n\n                        ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element12 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element12);
                    morphs[1] = dom.createMorphAt(element12,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[609,121],[609,135]]]],["get","article.id",["loc",[null,[609,136],[609,146]]]]],[],["loc",[null,[609,93],[609,148]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[609,149],[609,272]]]],
                    ["content","article.dosingInfo",["loc",[null,[609,283],[609,305]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 604,
                      "column": 18
                    },
                    "end": {
                      "line": 613,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element13 = dom.childAt(fragment, [1]);
                  var element14 = dom.childAt(element13, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element14,0,0);
                  morphs[1] = dom.createMorphAt(element14,2,2);
                  morphs[2] = dom.createMorphAt(element13,3,3);
                  morphs[3] = dom.createMorphAt(element13,5,5);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[606,26],[606,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[606,55],[606,72]]]]],[],0,1,["loc",[null,[606,49],[607,173]]]],
                  ["block","if",[["get","article.applied",["loc",[null,[608,30],[608,45]]]]],[],2,null,["loc",[null,[608,24],[608,205]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[609,30],[609,44]]]]],[],3,null,["loc",[null,[609,24],[612,31]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2, child3]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 602,
                    "column": 16
                  },
                  "end": {
                    "line": 614,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","block mb5 mt10 small");
                var el2 = dom.createTextNode("Erhaltene Ernährung:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.feeding",["loc",[null,[604,26],[604,46]]]]],[],0,null,["loc",[null,[604,18],[613,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child11 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 619,
                          "column": 22
                        },
                        "end": {
                          "line": 625,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("img");
                      dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                      dom.setAttribute(el2,"class","mr5");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2,"class","is-highlighted cursor");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element11 = dom.childAt(fragment, [1, 3]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element11);
                      morphs[1] = dom.createMorphAt(element11,0,0);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["gotoTemplate",["get","item.referenceId",["loc",[null,[622,83],[622,99]]]],["get","template.id",["loc",[null,[623,68],[623,79]]]],"treatment",["get","item.patient.id",["loc",[null,[623,92],[623,107]]]]],[],["loc",[null,[622,59],[623,109]]]],
                      ["content","template.name",["loc",[null,[623,110],[623,127]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 618,
                        "column": 20
                      },
                      "end": {
                        "line": 626,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","template.name",["loc",[null,[619,28],[619,41]]]]],[],0,null,["loc",[null,[619,22],[625,29]]]]
                  ],
                  locals: ["template"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 616,
                      "column": 18
                    },
                    "end": {
                      "line": 627,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Vorlagen:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","item.payload.templates",["loc",[null,[618,28],[618,50]]]]],[],0,null,["loc",[null,[618,20],[626,29]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 634,
                          "column": 81
                        },
                        "end": {
                          "line": 634,
                          "column": 115
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","report.name",["loc",[null,[634,100],[634,115]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 634,
                          "column": 115
                        },
                        "end": {
                          "line": 634,
                          "column": 133
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("Kein Titel");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 630,
                        "column": 20
                      },
                      "end": {
                        "line": 636,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                    dom.setAttribute(el2,"class","mr5");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","is-highlighted cursor");
                    var el3 = dom.createTextNode(" ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element10 = dom.childAt(fragment, [1, 3]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element10);
                    morphs[1] = dom.createMorphAt(element10,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["gotoReport",["get","item.referenceId",["loc",[null,[633,81],[633,97]]]],["get","report.id",["loc",[null,[634,68],[634,77]]]]],[],["loc",[null,[633,59],[634,79]]]],
                    ["block","if",[["get","report.name",["loc",[null,[634,87],[634,98]]]]],[],0,1,["loc",[null,[634,81],[634,140]]]]
                  ],
                  locals: ["report"],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 628,
                      "column": 18
                    },
                    "end": {
                      "line": 637,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Berichte:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","item.payload.reports",["loc",[null,[630,28],[630,48]]]]],[],0,null,["loc",[null,[630,20],[636,29]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 615,
                    "column": 16
                  },
                  "end": {
                    "line": 638,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.templates",["loc",[null,[616,24],[616,46]]]]],[],0,null,["loc",[null,[616,18],[627,25]]]],
                ["block","if",[["get","item.payload.reports",["loc",[null,[628,24],[628,44]]]]],[],1,null,["loc",[null,[628,18],[637,25]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child12 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 643,
                        "column": 18
                      },
                      "end": {
                        "line": 650,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","exp icon-button list-action-square line-action");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element8, 'style');
                    morphs[1] = dom.createMorphAt(element8,1,1);
                    return morphs;
                  },
                  statements: [
                    ["attribute","style",["concat",["right: ",["subexpr","css-bool-evaluator",[["get","showCopy",["loc",[null,[644,58],[644,66]]]],"145px","100px"],[],["loc",[null,[644,37],[644,84]]]]]]],
                    ["inline","button-hide-app-with-confirm",[],["confirmed","hideTreatment","size","28","refId",["subexpr","@mut",[["get","item.referenceId",["loc",[null,[646,59],[646,75]]]]],[],[]],"classNames","","color","#3B6182","content","In eltiga App sichtbar","text","Wollen Sie diese Behandlung für eltiga App Benutzer:innen wirklich ausblenden?"],["loc",[null,[645,22],[648,140]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 651,
                          "column": 20
                        },
                        "end": {
                          "line": 657,
                          "column": 20
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square line-action");
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element7 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element7, 'style');
                      morphs[1] = dom.createElementMorph(element7);
                      morphs[2] = dom.createMorphAt(element7,1,1);
                      return morphs;
                    },
                    statements: [
                      ["attribute","style",["concat",["right: ",["subexpr","css-bool-evaluator",[["get","showCopy",["loc",[null,[652,60],[652,68]]]],"145px","100px"],[],["loc",[null,[652,39],[652,86]]]]]]],
                      ["element","action",["unhideTreatment",["get","item.referenceId",["loc",[null,[652,115],[652,131]]]]],[],["loc",[null,[652,88],[652,133]]]],
                      ["inline","button-unhide-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content","In eltiga App ausgeblendet"],["loc",[null,[654,24],[655,82]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 657,
                          "column": 20
                        },
                        "end": {
                          "line": 663,
                          "column": 20
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square line-action");
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element6 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element6, 'style');
                      morphs[1] = dom.createElementMorph(element6);
                      morphs[2] = dom.createMorphAt(element6,1,1);
                      return morphs;
                    },
                    statements: [
                      ["attribute","style",["concat",["right: ",["subexpr","css-bool-evaluator",[["get","showCopy",["loc",[null,[658,60],[658,68]]]],"145px","100px"],[],["loc",[null,[658,39],[658,86]]]]]]],
                      ["element","action",["unhideTreatmentNotAllowed",["get","item.referenceId",["loc",[null,[658,125],[658,141]]]]],[],["loc",[null,[658,88],[658,143]]]],
                      ["inline","button-unhide-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content","In eltiga App ausgeblendet"],["loc",[null,[660,24],[661,82]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 650,
                        "column": 18
                      },
                      "end": {
                        "line": 665,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","item.payload.eltigaShareable",["loc",[null,[651,26],[651,54]]]]],[],0,1,["loc",[null,[651,20],[663,27]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 667,
                        "column": 18
                      },
                      "end": {
                        "line": 672,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square line-action exp");
                    dom.setAttribute(el1,"style","right: 100px;");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element5 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element5);
                    morphs[1] = dom.createMorphAt(element5,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["copy",["get","item.referenceId",["loc",[null,[668,39],[668,55]]]]],[],["loc",[null,[668,23],[668,57]]]],
                    ["inline","button-copy-list",[],["showStroke",false,"size","42","color","#fff","classNames",""],["loc",[null,[670,22],[670,96]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 642,
                      "column": 16
                    },
                    "end": {
                      "line": 684,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square exp line-action");
                  dom.setAttribute(el1,"style","right: 55px;");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square exp line-action");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element9 = dom.childAt(fragment, [4]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createElementMorph(element9);
                  morphs[3] = dom.createMorphAt(element9,1,1);
                  morphs[4] = dom.createMorphAt(dom.childAt(fragment, [6]),1,1);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.payload.eltigaShared",["loc",[null,[643,24],[643,49]]]]],[],0,1,["loc",[null,[643,18],[665,25]]]],
                  ["block","if",[["get","showCopy",["loc",[null,[667,24],[667,32]]]]],[],2,null,["loc",[null,[667,18],[672,25]]]],
                  ["element","action",["editTreatment",["get","item.referenceId",["loc",[null,[674,46],[674,62]]]]],[],["loc",[null,[674,21],[674,64]]]],
                  ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[676,20],[676,75]]]],
                  ["inline","button-delete-with-confirm-full",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[680,103],[680,107]]]]],[],[]],"confirmed","deleteTreatment","text","Wollen Sie diese Behandlung wirklich löschen?"],["loc",[null,[680,20],[682,103]]]]
                ],
                locals: [],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 641,
                    "column": 14
                  },
                  "end": {
                    "line": 685,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","unless",[["get","item.payload.eltigaRecord",["loc",[null,[642,26],[642,51]]]]],[],0,null,["loc",[null,[642,16],[684,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child13 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 697,
                      "column": 20
                    },
                    "end": {
                      "line": 701,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","dropdown-item");
                  var el2 = dom.createTextNode("\n                        Bericht erstellen\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element1);
                  return morphs;
                },
                statements: [
                  ["element","action",["openReportChooser",["get","item.referenceId",["loc",[null,[698,54],[698,70]]]]],[],["loc",[null,[698,25],[698,72]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 701,
                        "column": 20
                      },
                      "end": {
                        "line": 705,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","dropdown-item");
                    var el2 = dom.createTextNode("\n                        Bericht(e) bearbeiten\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                    ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openReportChooser",["get","item.referenceId",["loc",[null,[702,54],[702,70]]]]],[],["loc",[null,[702,25],[702,72]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 701,
                      "column": 20
                    },
                    "end": {
                      "line": 705,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","gt",[["get","item.payload.reportCount",["loc",[null,[701,34],[701,58]]]],0],[],["loc",[null,[701,30],[701,61]]]]],[],0,null,["loc",[null,[701,20],[705,20]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 687,
                    "column": 14
                  },
                  "end": {
                    "line": 708,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","dropdown");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","ml5 icon-button icon-button--default list-action-square dropdown");
                dom.setAttribute(el2,"data-toggle","dropdown");
                dom.setAttribute(el2,"aria-haspopup","true");
                dom.setAttribute(el2,"aria-expanded","false");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createTextNode("Mehr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","dropdown-menu");
                dom.setAttribute(el2,"aria-labelledby","dropdownMenuButton");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","dropdown-item");
                var el4 = dom.createTextNode("\n                      Bearbeiten\n                    ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var element3 = dom.childAt(element2, [3]);
                var element4 = dom.childAt(element3, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
                morphs[1] = dom.createElementMorph(element4);
                morphs[2] = dom.createMorphAt(element3,3,3);
                return morphs;
              },
              statements: [
                ["inline","button-more-hor",[],["showStroke",false,"color","#fff","size",40,"content",""],["loc",[null,[691,20],[691,88]]]],
                ["element","action",["editTreatment",["get","item.referenceId",["loc",[null,[694,48],[694,64]]]]],[],["loc",[null,[694,23],[694,66]]]],
                ["block","if",[["subexpr","eq",[["get","item.payload.reportCount",["loc",[null,[697,30],[697,54]]]],0],[],["loc",[null,[697,26],[697,57]]]]],[],0,1,["loc",[null,[697,20],[705,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 543,
                  "column": 10
                },
                "end": {
                  "line": 713,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box treatment");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element25 = dom.childAt(fragment, [1]);
              var element26 = dom.childAt(element25, [1]);
              var morphs = new Array(14);
              morphs[0] = dom.createMorphAt(element26,1,1);
              morphs[1] = dom.createMorphAt(element26,2,2);
              morphs[2] = dom.createMorphAt(element26,3,3);
              morphs[3] = dom.createMorphAt(element26,4,4);
              morphs[4] = dom.createMorphAt(element26,5,5);
              morphs[5] = dom.createMorphAt(element26,6,6);
              morphs[6] = dom.createMorphAt(element26,7,7);
              morphs[7] = dom.createMorphAt(element26,8,8);
              morphs[8] = dom.createMorphAt(element26,9,9);
              morphs[9] = dom.createMorphAt(element26,10,10);
              morphs[10] = dom.createMorphAt(element26,11,11);
              morphs[11] = dom.createMorphAt(element26,12,12);
              morphs[12] = dom.createMorphAt(element25,3,3);
              morphs[13] = dom.createMorphAt(element25,5,5);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.payload.medicalHistory",["loc",[null,[546,22],[546,49]]]]],[],0,null,["loc",[null,[546,16],[548,23]]]],
              ["block","if",[["get","item.payload.sypmtoms",["loc",[null,[549,22],[549,43]]]]],[],1,null,["loc",[null,[549,16],[551,23]]]],
              ["block","if",[["get","item.payload.diagnosis",["loc",[null,[552,22],[552,44]]]]],[],2,null,["loc",[null,[552,16],[554,23]]]],
              ["block","if",[["get","item.payload.therapy",["loc",[null,[555,22],[555,42]]]]],[],3,null,["loc",[null,[555,16],[557,23]]]],
              ["block","if",[["get","item.payload.report",["loc",[null,[558,22],[558,41]]]]],[],4,null,["loc",[null,[558,16],[560,23]]]],
              ["block","if",[["get","item.payload.rating",["loc",[null,[561,22],[561,41]]]]],[],5,null,["loc",[null,[561,16],[565,23]]]],
              ["block","if",[["get","item.payload.manualTreatment",["loc",[null,[566,22],[566,50]]]]],[],6,null,["loc",[null,[566,16],[568,23]]]],
              ["block","if",[["get","item.payload.services",["loc",[null,[569,22],[569,43]]]]],[],7,null,["loc",[null,[569,16],[579,23]]]],
              ["block","if",[["get","item.payload.products",["loc",[null,[580,22],[580,43]]]]],[],8,null,["loc",[null,[580,16],[590,23]]]],
              ["block","if",[["get","item.payload.meds",["loc",[null,[591,22],[591,39]]]]],[],9,null,["loc",[null,[591,16],[601,23]]]],
              ["block","if",[["get","item.payload.feeding",["loc",[null,[602,22],[602,42]]]]],[],10,null,["loc",[null,[602,16],[614,23]]]],
              ["block","unless",[["get","item.eltigaRecord",["loc",[null,[615,26],[615,43]]]]],[],11,null,["loc",[null,[615,16],[638,27]]]],
              ["block","unless",[["get","item.eltigaRecord",["loc",[null,[641,24],[641,41]]]]],[],12,null,["loc",[null,[641,14],[685,25]]]],
              ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[687,20],[687,28]]]]],[],13,null,["loc",[null,[687,14],[708,21]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 253,
                "column": 4
              },
              "end": {
                "line": 717,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","timeline-item");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","timeline-line");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","pe-3 mb10");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","d-flex align-items-center mt-1 fs-6");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","me-2 fs-7");
            var el6 = dom.createElement("b");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(", ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                um ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                Uhr");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","tags ml20");
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","headline mt5 flex-center flex-gap-5");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element60 = dom.childAt(fragment, [1]);
            var element61 = dom.childAt(element60, [3]);
            var element62 = dom.childAt(element61, [1, 1]);
            var element63 = dom.childAt(element60, [5]);
            var element64 = dom.childAt(element63, [1]);
            var element65 = dom.childAt(element64, [1]);
            var element66 = dom.childAt(element65, [1, 0]);
            var element67 = dom.childAt(element65, [3]);
            var morphs = new Array(21);
            morphs[0] = dom.createAttrMorph(element61, 'class');
            morphs[1] = dom.createAttrMorph(element62, 'src');
            morphs[2] = dom.createAttrMorph(element63, 'class');
            morphs[3] = dom.createMorphAt(element66,0,0);
            morphs[4] = dom.createMorphAt(element66,2,2);
            morphs[5] = dom.createMorphAt(element66,4,4);
            morphs[6] = dom.createMorphAt(element67,1,1);
            morphs[7] = dom.createMorphAt(element67,2,2);
            morphs[8] = dom.createMorphAt(dom.childAt(element64, [3]),1,1);
            morphs[9] = dom.createMorphAt(element64,5,5);
            morphs[10] = dom.createMorphAt(element63,3,3);
            morphs[11] = dom.createMorphAt(element63,4,4);
            morphs[12] = dom.createMorphAt(element63,5,5);
            morphs[13] = dom.createMorphAt(element63,6,6);
            morphs[14] = dom.createMorphAt(element63,7,7);
            morphs[15] = dom.createMorphAt(element63,8,8);
            morphs[16] = dom.createMorphAt(element63,9,9);
            morphs[17] = dom.createMorphAt(element63,10,10);
            morphs[18] = dom.createMorphAt(element63,11,11);
            morphs[19] = dom.createMorphAt(element63,12,12);
            morphs[20] = dom.createMorphAt(element63,13,13);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["timeline-icon color-",["get","item.type",["loc",[null,[257,42],[257,51]]]]]]],
            ["attribute","src",["concat",["/assets/images/icons/history/",["get","item.type",["loc",[null,[259,53],[259,62]]]],".svg"]]],
            ["attribute","class",["concat",["timeline-content ",["get","item.type",["loc",[null,[262,39],[262,48]]]]," mb-10 mt-n1"]]],
            ["inline","get-weekday",[["get","item.date",["loc",[null,[265,54],[265,63]]]]],[],["loc",[null,[265,40],[265,65]]]],
            ["inline","time-format",[["get","item.date",["loc",[null,[265,81],[265,90]]]],"DD. MM. YYYY"],[],["loc",[null,[265,67],[265,107]]]],
            ["inline","time-format",[["get","item.date",["loc",[null,[266,33],[266,42]]]],"HH:mm"],[],["loc",[null,[266,19],[266,52]]]],
            ["block","each",[["get","item.tags",["loc",[null,[269,24],[269,33]]]]],[],0,null,["loc",[null,[269,16],[271,25]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[272,26],[272,35]]]],"lab"],[],["loc",[null,[272,22],[272,42]]]]],[],1,null,["loc",[null,[272,16],[280,23]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[286,24],[286,33]]]],"treat"],[],["loc",[null,[286,20],[286,42]]]]],[],2,3,["loc",[null,[286,14],[328,21]]]],
            ["block","if",[["subexpr","and",[["get","item.patient",["loc",[null,[330,23],[330,35]]]],["get","showPatientInfo",["loc",[null,[330,36],[330,51]]]]],[],["loc",[null,[330,18],[330,52]]]]],[],4,null,["loc",[null,[330,12],[332,19]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[335,20],[335,29]]]],"com"],[],["loc",[null,[335,16],[335,36]]]]],[],5,null,["loc",[null,[335,10],[350,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[351,20],[351,29]]]],"app"],[],["loc",[null,[351,16],[351,36]]]]],[],6,null,["loc",[null,[351,10],[361,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[362,20],[362,29]]]],"not"],[],["loc",[null,[362,16],[362,36]]]]],[],7,null,["loc",[null,[362,10],[372,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[373,20],[373,29]]]],"rem"],[],["loc",[null,[373,16],[373,36]]]]],[],8,null,["loc",[null,[373,10],[379,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[380,20],[380,29]]]],"mail"],[],["loc",[null,[380,16],[380,37]]]]],[],9,null,["loc",[null,[380,10],[386,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[387,20],[387,29]]]],"invoice"],[],["loc",[null,[387,16],[387,40]]]]],[],10,null,["loc",[null,[387,10],[479,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[480,20],[480,29]]]],"doc"],[],["loc",[null,[480,16],[480,36]]]]],[],11,null,["loc",[null,[480,10],[498,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[499,20],[499,29]]]],"vacc"],[],["loc",[null,[499,16],[499,37]]]]],[],12,null,["loc",[null,[499,10],[507,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[508,20],[508,29]]]],"lab"],[],["loc",[null,[508,16],[508,36]]]]],[],13,null,["loc",[null,[508,10],[529,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[530,20],[530,29]]]],"img"],[],["loc",[null,[530,16],[530,36]]]]],[],14,null,["loc",[null,[530,10],[542,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[543,20],[543,29]]]],"treat"],[],["loc",[null,[543,16],[543,38]]]]],[],15,null,["loc",[null,[543,10],[713,17]]]]
          ],
          locals: ["item"],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 251,
              "column": 4
            },
            "end": {
              "line": 721,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination-wrapper is-fixed");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","items",["loc",[null,[253,12],[253,17]]]]],[],0,null,["loc",[null,[253,4],[717,13]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","items",["loc",[null,[719,29],[719,34]]]]],[],[]],"numPagesToShow",7],["loc",[null,[719,6],[719,53]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 790,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","patientActivityWrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","mb30 mt20 grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter standalone mb10");
        dom.setAttribute(el3,"style","margin-right: -10px");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"style","padding-right: 9px");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","");
        dom.setAttribute(el6,"style","line-height: 30px");
        var el7 = dom.createTextNode("Alle");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small  l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/treat.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Behandlungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/invoice.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Rechnungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/app.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Termine");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/rem.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Erinnerungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/not.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Notizen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/doc.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Dokumente");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/lab.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Labor");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/img.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Bildgebung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/mail.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        E-Mail");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/com.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        eltiga Chat");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/vacc.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Impfungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-7/24@desk-wide l-8/24@desk l-24/24 gutter");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","pickDate");
        dom.setAttribute(el4,"class","date-picker input-element");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title mt0");
        var el6 = dom.createTextNode("Zeitraum");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"style","display: inline-block;width: 100%;padding-right: 55px;");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","field-icon");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("i");
        dom.setAttribute(el7,"class","fa fa-calendar");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","u-mb0 field select");
        dom.setAttribute(el5,"style","position: absolute;right: 0");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","switchFiltersJournal");
        dom.setAttribute(el6,"class","button filter-toggle");
        dom.setAttribute(el6,"style","border:none;background-color: #cccccc;color: #fff;display: block;height: 46px;width: 46px;padding:7px 10px 10px;");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"style","height: 30px;width: 30px;");
        dom.setAttribute(el7,"src","assets/images/icons/icon-filter.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter text-right col l-17/24@desk-wide l-16/24@desk l-24/24 pl5");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"style","font-weight: normal");
        dom.setAttribute(el4,"class","block title mb10");
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","timeline");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n    $('#pickDateJournal').daterangepicker({\n      \"autoApply\": true,\n      \"ranges\": {\n        \"Alle\": [moment('2016-01-01T00:00:00.000'), moment()],\n        \"Heute\": [moment(), moment()],\n        \"Gestern\": [moment().subtract(1, 'days'), moment().subtract(1, 'days')],\n        \"Diese Woche\": [moment().startOf('isoWeek'), moment().startOf('isoWeek').add(7,'days')],\n        \"Letzten 7 Tage\": [moment().subtract(6, 'days'), moment()],\n        \"Dieses Monat\": [moment().startOf('month'), moment().endOf('month')],\n        \"Letzten 30 Tage\": [moment().subtract(30, 'days'), moment()],\n        \"Letzten 6 Monate\": [moment().subtract(6, 'months'), moment()],\n        \"Dieses Jahr\": [moment().startOf('year'), moment()],\n      },\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY\",\n        \"separator\": \" - \",\n        \"applyLabel\": \"Anwenden\",\n        \"cancelLabel\": \"Abbrechen\",\n        \"fromLabel\": \"Von\",\n        \"toLabel\": \"Bis\",\n        \"customRangeLabel\": \"Benutzerdefiniert\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n      \"linkedCalendars\": false\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element69 = dom.childAt(fragment, [0]);
        var element70 = dom.childAt(element69, [1]);
        var element71 = dom.childAt(element70, [1]);
        var element72 = dom.childAt(element71, [1, 1]);
        var element73 = dom.childAt(element71, [3, 1]);
        var element74 = dom.childAt(element71, [5, 1]);
        var element75 = dom.childAt(element71, [7, 1]);
        var element76 = dom.childAt(element71, [9, 1]);
        var element77 = dom.childAt(element71, [11, 1]);
        var element78 = dom.childAt(element71, [13, 1]);
        var element79 = dom.childAt(element71, [15, 1]);
        var element80 = dom.childAt(element71, [17, 1]);
        var element81 = dom.childAt(element71, [19, 1]);
        var element82 = dom.childAt(element71, [21, 1]);
        var element83 = dom.childAt(element71, [23, 1]);
        var element84 = dom.childAt(element70, [3, 1]);
        var morphs = new Array(32);
        morphs[0] = dom.createAttrMorph(element72, 'class');
        morphs[1] = dom.createElementMorph(element72);
        morphs[2] = dom.createAttrMorph(element73, 'class');
        morphs[3] = dom.createElementMorph(element73);
        morphs[4] = dom.createAttrMorph(element74, 'class');
        morphs[5] = dom.createElementMorph(element74);
        morphs[6] = dom.createAttrMorph(element75, 'class');
        morphs[7] = dom.createElementMorph(element75);
        morphs[8] = dom.createAttrMorph(element76, 'class');
        morphs[9] = dom.createElementMorph(element76);
        morphs[10] = dom.createAttrMorph(element77, 'class');
        morphs[11] = dom.createElementMorph(element77);
        morphs[12] = dom.createAttrMorph(element78, 'class');
        morphs[13] = dom.createElementMorph(element78);
        morphs[14] = dom.createAttrMorph(element79, 'class');
        morphs[15] = dom.createElementMorph(element79);
        morphs[16] = dom.createAttrMorph(element80, 'class');
        morphs[17] = dom.createElementMorph(element80);
        morphs[18] = dom.createAttrMorph(element81, 'class');
        morphs[19] = dom.createElementMorph(element81);
        morphs[20] = dom.createAttrMorph(element82, 'class');
        morphs[21] = dom.createElementMorph(element82);
        morphs[22] = dom.createAttrMorph(element83, 'class');
        morphs[23] = dom.createElementMorph(element83);
        morphs[24] = dom.createMorphAt(dom.childAt(element84, [3]),1,1);
        morphs[25] = dom.createMorphAt(dom.childAt(element84, [5]),1,1);
        morphs[26] = dom.createMorphAt(dom.childAt(element70, [5]),3,3);
        morphs[27] = dom.createMorphAt(element70,7,7);
        morphs[28] = dom.createMorphAt(dom.childAt(element69, [3]),1,1);
        morphs[29] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[30] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[31] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["selectable-item text-center align-items-center color-all ",["subexpr","css-bool-evaluator",[["get","typeAllSelected",["loc",[null,[6,98],[6,113]]]],"selected-style",""],[],["loc",[null,[6,77],[7,91]]]]]]],
        ["element","action",["toggleTypeFilter","all"],[],["loc",[null,[7,93],[8,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-treat ",["subexpr","css-bool-evaluator",[["get","typeTreatmentSelected",["loc",[null,[11,88],[11,109]]]],"selected-style",""],[],["loc",[null,[11,67],[12,110]]]]]]],
        ["element","action",["toggleTypeFilter","treat"],[],["loc",[null,[12,112],[13,36]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-invoice ",["subexpr","css-bool-evaluator",[["get","typeInvoiceSelected",["loc",[null,[18,90],[18,109]]]],"selected-style",""],[],["loc",[null,[18,69],[20,95]]]]]]],
        ["element","action",["toggleTypeFilter","invoice"],[],["loc",[null,[20,97],[21,38]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-app ",["subexpr","css-bool-evaluator",[["get","typeAppSelected",["loc",[null,[26,86],[26,101]]]],"selected-style",""],[],["loc",[null,[26,65],[27,91]]]]]]],
        ["element","action",["toggleTypeFilter","app"],[],["loc",[null,[27,93],[28,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-rem ",["subexpr","css-bool-evaluator",[["get","typeRemSelected",["loc",[null,[33,86],[33,101]]]],"selected-style",""],[],["loc",[null,[33,65],[34,91]]]]]]],
        ["element","action",["toggleTypeFilter","rem"],[],["loc",[null,[34,93],[35,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-not ",["subexpr","css-bool-evaluator",[["get","typeNotSelected",["loc",[null,[40,86],[40,101]]]],"selected-style",""],[],["loc",[null,[40,65],[41,91]]]]]]],
        ["element","action",["toggleTypeFilter","not"],[],["loc",[null,[41,93],[42,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-doc ",["subexpr","css-bool-evaluator",[["get","typeDocSelected",["loc",[null,[47,86],[47,101]]]],"selected-style",""],[],["loc",[null,[47,65],[48,91]]]]]]],
        ["element","action",["toggleTypeFilter","doc"],[],["loc",[null,[48,93],[49,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-lab ",["subexpr","css-bool-evaluator",[["get","typeLabSelected",["loc",[null,[54,86],[54,101]]]],"selected-style",""],[],["loc",[null,[54,65],[55,91]]]]]]],
        ["element","action",["toggleTypeFilter","lab"],[],["loc",[null,[55,93],[56,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-img ",["subexpr","css-bool-evaluator",[["get","typeImgSelected",["loc",[null,[61,86],[61,101]]]],"selected-style",""],[],["loc",[null,[61,65],[62,91]]]]]]],
        ["element","action",["toggleTypeFilter","img"],[],["loc",[null,[62,93],[63,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-mail ",["subexpr","css-bool-evaluator",[["get","typeMailSelected",["loc",[null,[68,87],[68,103]]]],"selected-style",""],[],["loc",[null,[68,66],[69,92]]]]]]],
        ["element","action",["toggleTypeFilter","mail"],[],["loc",[null,[69,94],[70,35]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-com ",["subexpr","css-bool-evaluator",[["get","typeComSelected",["loc",[null,[75,86],[75,101]]]],"selected-style",""],[],["loc",[null,[75,65],[76,91]]]]]]],
        ["element","action",["toggleTypeFilter","com"],[],["loc",[null,[76,93],[77,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-vacc ",["subexpr","css-bool-evaluator",[["get","typeVaccSelected",["loc",[null,[82,87],[82,103]]]],"selected-style",""],[],["loc",[null,[82,66],[83,92]]]]]]],
        ["element","action",["toggleTypeFilter","vacc"],[],["loc",[null,[83,94],[84,35]]]],
        ["inline","input",[],["id","pickDateJournal","value",["subexpr","@mut",[["get","pickDateJournal",["loc",[null,[94,45],[94,60]]]]],[],[]],"class","new-style input input--date-picker ember-view ember-text-field","name","pickDateJournal"],["loc",[null,[94,10],[95,114]]]],
        ["inline","input",[],["type","checkbox","id","switchFiltersJournal","classNames","switch","name","switchFiltersJournal","checked",["subexpr","@mut",[["get","showFilters",["loc",[null,[101,116],[101,127]]]]],[],[]]],["loc",[null,[101,10],[101,129]]]],
        ["block","each",[["get","availableTags",["loc",[null,[110,14],[110,27]]]]],[],0,null,["loc",[null,[110,6],[113,15]]]],
        ["block","if",[["get","showFilters",["loc",[null,[116,10],[116,21]]]]],[],1,null,["loc",[null,[116,4],[244,11]]]],
        ["block","if",[["get","loading",["loc",[null,[249,10],[249,17]]]]],[],2,3,["loc",[null,[249,4],[721,11]]]],
        ["inline","treatment-history-report-chooser",[],["chooseReport","gotoReport","openWizard","openReportWizard","delete","deleteReport","prefix","-journal","actionReceiver",["subexpr","@mut",[["get","treatmentHistoryReportChooserActivities",["loc",[null,[730,17],[730,56]]]]],[],[]]],["loc",[null,[725,0],[730,58]]]],
        ["inline","treatment-report-wizzard-journal",[],["saveFinished","openReportPreview","actionReceiver",["subexpr","@mut",[["get","treatmentReportWizzardJournal",["loc",[null,[734,17],[734,46]]]]],[],[]]],["loc",[null,[732,0],[734,48]]]],
        ["content","eltiga-sharenotallowed-panel",["loc",[null,[736,0],[736,32]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});